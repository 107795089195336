<template>
  <div>
    <div class="aiw-box" v-if="!aiContent">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="aiw-logo">AIW</div>
          <div class="aiw-text">Artificial Inteligence Waiter</div>
        </div>
        <div class="close-box" @click="closeAI()">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.65753 7.73973C9.52055 7.60274 9.52055 7.39726 9.65753 7.26027L14.7945 2.12329C14.9315 1.9863 15 1.78082 15 1.64384C15 1.50685 14.9315 1.30137 14.7945 1.16438L13.8356 0.205479C13.6986 0.0684932 13.4932 0 13.3562 0C13.1507 0 13.0137 0.0684932 12.8767 0.205479L7.73973 5.34247C7.60274 5.47945 7.39726 5.47945 7.26027 5.34247L2.12329 0.205479C1.9863 0.0684932 1.78082 0 1.64384 0C1.50685 0 1.30137 0.0684932 1.16438 0.205479L0.205479 1.16438C0.0684932 1.30137 0 1.50685 0 1.64384C0 1.78082 0.0684932 1.9863 0.205479 2.12329L5.34247 7.26027C5.47945 7.39726 5.47945 7.60274 5.34247 7.73973L0.205479 12.8767C0.0684932 13.0137 0 13.2192 0 13.3562C0 13.4932 0.0684932 13.6986 0.205479 13.8356L1.16438 14.7945C1.30137 14.9315 1.50685 15 1.64384 15C1.78082 15 1.9863 14.9315 2.12329 14.7945L7.26027 9.65753C7.39726 9.52055 7.60274 9.52055 7.73973 9.65753L12.8767 14.7945C13.0137 14.9315 13.2192 15 13.3562 15C13.4932 15 13.6986 14.9315 13.8356 14.7945L14.7945 13.8356C14.9315 13.6986 15 13.4932 15 13.3562C15 13.2192 14.9315 13.0137 14.7945 12.8767L9.65753 7.73973Z"
              fill="#EDEDED"
            />
          </svg>
        </div>
      </div>
      <p class="pt-2">{{ $t('aiwaiter_survey.help_us') }}</p>
      <button class="ok-thats-epic" @click="startPoll">
        {{ $t('aiwaiter_survey.start_button') }}
      </button>
    </div>
    <div class="aiw-box survey-modal h-100" v-if="aiContent">
      <div class="close-box float-right" @click="closeAI()">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.65753 7.73973C9.52055 7.60274 9.52055 7.39726 9.65753 7.26027L14.7945 2.12329C14.9315 1.9863 15 1.78082 15 1.64384C15 1.50685 14.9315 1.30137 14.7945 1.16438L13.8356 0.205479C13.6986 0.0684932 13.4932 0 13.3562 0C13.1507 0 13.0137 0.0684932 12.8767 0.205479L7.73973 5.34247C7.60274 5.47945 7.39726 5.47945 7.26027 5.34247L2.12329 0.205479C1.9863 0.0684932 1.78082 0 1.64384 0C1.50685 0 1.30137 0.0684932 1.16438 0.205479L0.205479 1.16438C0.0684932 1.30137 0 1.50685 0 1.64384C0 1.78082 0.0684932 1.9863 0.205479 2.12329L5.34247 7.26027C5.47945 7.39726 5.47945 7.60274 5.34247 7.73973L0.205479 12.8767C0.0684932 13.0137 0 13.2192 0 13.3562C0 13.4932 0.0684932 13.6986 0.205479 13.8356L1.16438 14.7945C1.30137 14.9315 1.50685 15 1.64384 15C1.78082 15 1.9863 14.9315 2.12329 14.7945L7.26027 9.65753C7.39726 9.52055 7.60274 9.52055 7.73973 9.65753L12.8767 14.7945C13.0137 14.9315 13.2192 15 13.3562 15C13.4932 15 13.6986 14.9315 13.8356 14.7945L14.7945 13.8356C14.9315 13.6986 15 13.4932 15 13.3562C15 13.2192 14.9315 13.0137 14.7945 12.8767L9.65753 7.73973Z"
            fill="#E2223B"
          />
        </svg>
      </div>
      <div class="panda-container">
        <img src="/hero-pics/eat.svg" alt="hero eat" />
      </div>

      <h5>
        {{ $t('aiwaiter_survey.describe_1') }}
        <br />{{ $t('aiwaiter_survey.describe_2') }}
      </h5>
      <small>{{ $t('aiwaiter_survey.example') }}</small>
      <textarea :class="foodInputClass" v-model="user_input"></textarea>
      <button class="finish-btn" @click="sendPoll">{{ $t('aiwaiter_survey.send') }}</button>
    </div>
  </div>
</template>

<script>
import AIWaiterAPI from '@/api/aiwaiter'

import TelemetryService from '@/services/telemetry'

export default {
  data() {
    return {
      aiContent: false,
      valid: true,

      user_input: null
    };
  },
  props: {
    target: Object
  },
  methods: {
    closeAI() {
      this.$emit("closeAI");
    },
    startPoll() {
      this.aiContent = true;

      this.toggleGlobalScrolling(true);
    },
    sendPoll() {
      if (!this.user_input || this.user_input.length < 3) {
        this.valid = false;
        return;
      }

      this.valid = true;

      AIWaiterAPI.sendSurveyResult({
        cafe_id: this.target.cafe_id,
        content: this.user_input,
        telemetry_id: TelemetryService.getTelemetryId()
      }).finally(() => {
        this.$emit('closeAI');
      });
    }
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  computed: {
    foodInputClass() {
      return {
        'input-food': true,
        'red-input': !this.valid
      }
    }
  }
};
</script>

<style scoped>
.aiw-box {
  background: black;
  position: relative;
  padding: 2.5vh;
  top: 0;
  margin-bottom: 2.5vh;
  width: 100%;
  color: #ededed;
}

.survey-modal {
  top: 7vh;
  position: fixed;
  z-index: 100;
  left: 0;
}

.h-100 {
  height: 100vh !important;
}

.finish-btn {
  position: fixed;
  bottom: 2.5vh;
  width: 90%;
  margin: 0 auto;
  border-radius: 5vh;
  left: 0;
  right: 0;
  background: #129b81;
  font-size: 2.2vh;
  color: #ededed;
  padding: 1.5vh;
  border: none;
}

h5 {
  font-size: 2vh;
  margin: 0;
  padding-top: 1vh;
  text-align: center;
}

.input-food {
  width: 100%;
  border-radius: 1vh;
  font-size: 2vh;
  border: none;
  padding: 1vh;
  outline: none;
  margin-top: 1vh;
}

small {
  color: #a4a3a1;
  font-size: 1.5vh;
  display: block;
  padding-top: 3vh;
  line-height: 1.3;
}

.panda-container {
  text-align: center;
  width: 100vw;
}

.panda-container img {
  height: 16.5vh;
  width: 16vh;
}

.aiw-box img {
  /* height: 16.5vh;
  width: 16vh;
  margin: 0 auto;
  /* margin-left: 13vh; */
  /* display: block; */
}

.aiw-logo {
  border: 1px solid #ededed;
  border-radius: 1.5vh;
  padding: 1.7vh 0.9vh;
  align-self: start;
  line-height: normal;
  font-size: 2vh;
}

.ok-thats-epic {
  width: 100%;
  background: #129b81;
  border-radius: 1vh;
  color: #ededed;
  border: none;
  padding: 0.7vh;
  font-size: 2vh;
  margin-top: 2.5vh;
}

p {
  font-size: 2vh;
  margin: 0;
  line-height: normal;
  width: 75%;
}

.aiw-text {
  width: 22%;
  line-height: 1.1;
  font-size: 1.5vh;
  padding-left: 1.1vh;
  align-self: center;
}

.red-input {
  border: 2px solid #e2223b;
}
</style>