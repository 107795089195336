<template>
  <div>
    <div class="product-item">
      <img :src="productImage" class="product-image" />

      <!-- <ul v-if="product.ingridients">
        <li v-for="ingrid in productIngridientsList">{{ ingrid.trim()}}</li>
      </ul>-->
    </div>
    <div class="product-info">
      <h4 v-thematic>{{productDisplayName}}</h4>

      <span 
        :class="{
          'product-price': true,
          'product-discount-price': !!promotionDiscount
        }" 
        v-if="displayPrice">{{displayPrice}}{{currency.symbol}}</span>
      <!-- <p :class="product.description ? 'product-desc color-grey': 'no-space'" v-if="!hide_description">
        {{
        productDisplayDescription
        }}
      </p> -->
    </div>
  </div>
</template>

<style scoped>
.product-item img {
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 1.2vh;
  object-fit: cover;
}

.no-space {
  margin-bottom: 0;
}

.product-image img {
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 6px 6px 0px 0px;
}

.product-desc {
  font-size: 4.5vw;
  overflow: hidden;
  padding: 0;
  margin: 0.8vh 0 0 0;
  line-height: 5vw;
  font-weight: 100;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 50vw;
}

.product-info {
  position: relative;
}

h4 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 4.7vw;
  max-width: 37vw;
  color: black;
  margin-bottom: 0;
  padding: 1.5vh 0.5vw 0 0;
}

h4.dark {
  color: #ededed;
}

ul {
  padding: 1px 0 0 10px;
  font-weight: 100;
  margin-bottom: 0;
  color: #999999;
}

span {
  top: 0;
  right: 0;
  position: absolute;
  float: right;
  color: black;
  font-size: 5.3vw;
  font-weight: 500;
  padding: 0.7vh 1.6vw 0 0;
}

.product-item {
  cursor: pointer;
  height: 15.5vh;
  width: 53.5vw;
  padding-bottom: 0;
  margin: 0.8vh 0 0 0;
}

.product-price {
  color: #908F8D !important;
}

.product-discount-price {
  color: #129b81 !important;
}

@media screen and (min-width: 550px) {
  .product-item {
    width: 27.5vh;
    height: 15vh;
    margin: 0;
  }

  .product-info {
    padding-left: 0.6vh;
  }

  .product-desc {
    font-size: 2vh;
    word-break: break-all;
    line-height: 1;
    width: 100% !important;
    -webkit-line-clamp: 3;
  }
  h4 {
    font-size: 2vh;
    max-width: 17.5vh;
    padding: 1.3vh 0.5vh 0 0;
    letter-spacing: -1px !important;
  }
  span {
    font-size: 2.3vh;
    padding: 0.7vh 0.7vh 0 0;
  }
}
</style>

<script>
export default {
  props: {
    product: Object,
    hide_description: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    productDisplayName() {
      return this.$store.getters["menu/localize"](
        "products",
        this.product.name
      );
    },
    productDisplayDescription() {
      return this.$store.getters["menu/localize"](
        "descriptions",
        this.product.description
      );
    },
    productIngridientsList() {
      return this.$store.getters["menu/localize"](
        "ingridients",
        this.product.ingridients
      )
        .split(",")
        .slice(0, 3);
    },
    currency() {
      return this.$store.state.currency.current;
    },
    firstPrice() {
      const base_price = this.product.base_price || 0;

      const variants_groups = this.product.price_groups.filter(g => g.single_select);
      
      let variants_add = 0;

      let marked_merge = false;

      for (let i = 0; i < variants_groups.length; i++) {
        const items = variants_groups[i].items;

        if (variants_groups[i].show_for) {
          if (!marked_merge) {
            marked_merge = true;
          } else {
            continue;
          }
        }

        variants_add += items[0].price;
      }

      // if (variants_group && variants_group.items[0] && variants_group.items[0].price) {
      //   return variants_group.items[0].price + base_price;
      // }

      return base_price + variants_add;
    },
    displayPrice() {
      if (!this.promotionDiscount) return this.firstPrice;

      const precision = this.$store.state.currency.can_round_prices ? 1 : 100;
      
      return Math.round(this.firstPrice * this.promotionDiscount * precision) / precision;
    },
    promotionDiscount() {
      return this.$store.getters['promotions/getProductDiscount'](this.product);
    },
    productImage() {
      if (this.product.image_thumbnail) {
        return this.product.image_thumbnail;
      }

      if (!this.product.image) {
        return '/icons/no-dish.jpg';
      }

      if (this.product.image.includes('joinposter.com')) {
        return this.product.image.replace('_original', '');
      }
      
      return this.product.image;
    }
    // shortDisplayName() {
    //   if (this.productDisplayName.length < 15) return this.productDisplayName;

    //   return this.productDisplayName.substring(0,15) + '...';
    // }
  }
};
</script>

<style>
</style>    