function computeTotal(list) {
    let total = 0;
    list.forEach(item => {
        if (!item.available) return;

        let itemPrice = item.price;

        if (item.modificators) {
          item.modificators.forEach(mod => itemPrice += mod.price);
        }

        total = total + itemPrice * item.quantity;
    });

    return Math.round(total * 100) / 100;
}

export default {
    computeTotal
}