<template>
  <div>
    <div class="blur-content" @click="hideModal"></div>
    <div :class="is_exclusive ? 'modal-box black' : 'modal-box'">
      <div class="d-flex modal-space">
        <div class="info">
          <span class="category-name">{{ product.category }}</span>
          <h3 :class="is_exclusive ? 'dish-name color-white' : 'dish-name'">
            {{ product.name }}
          </h3>
          <p class="description">{{ product.description }}</p>
        </div>
        <div class="dish-pic">
          <img
            :src="product.image ? product.image : '/icons/no-dish.jpg'"
            alt="dish image"
          />
        </div>
      </div>
      <hr v-if="product.price_groups.length" />
      <div
        v-for="(price_group, group_index) in product.price_groups"
        :key="group_index"
      >
        <div
          class="variants"
          id="desktopVariantsContainer"
          v-if="price_group.single_select"
        >
          <h4 :class="is_exclusive ? 'color-white' : ''">
            {{ price_group.name || $t("menu.choose_variant") }}
          </h4>
          <div
            class="d-flex justify-content-start overflow-auto"
            id="desktopVariants"
          >
            <div class="d-flex" id="variantsLimiter">
              <div
                :style="is_exclusive ? 'box-shadow:none' : ''"
                :class="getVariantClass(price)"
                @click="onSelectVariant(price, price_group)"
                v-for="(price, index) in price_group.items"
                :key="index"
              >
                <h4>
                  {{ price.name }}
                  <span class="float-right">{{ price.price }}{{currency.symbol}}</span>
                </h4>
              </div>
            </div>
          </div>

          <RemoteScroller
            :exclusive="is_exclusive"
            class="variants-remote-scroller"
            :limit_id="`desktopVariantsContainer`"
            :id="`desktopVariants`"
          />
        </div>
        <!-- <hr v-if="product.modificators.length" /> -->
        <div
          class="additions"
          id="desktopModificatorsContainer"
          v-if="!price_group.single_select"
        >
          <h4 :class="is_exclusive ? 'color-white' : ''">
            {{ price_group.name || $t("menu.choose_modificators") }}
          </h4>
          <div
            class="d-flex justify-content-start overflow-auto"
            id="desktopModificators"
          >
            <div class="d-flex">
              <div
                :style="is_exclusive ? 'box-shadow:none' : ''"
                :class="getModificatorClass(mod)"
                @click="onSelectModificator(mod)"
                v-for="(mod, index) in price_group.items"
                :key="index"
              >
                <h4>
                  {{ mod.name }}
                  <span class="float-right">+{{ mod.price }}{{currency.symbol}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <RemoteScroller
          :exclusive="is_exclusive"
          class="variants-remote-scroller"
          :limit_id="`desktopModificatorsContainer`"
          :id="`desktopModificators`"
        />
      </div>
      <hr />
      <div :class="is_exclusive ? 'qnty-total color-white' : 'qnty-total pb-0'">
        <div class="w-50">
          <h4 class="pl-0">{{ $t("desktop_delivery.menu_quantity") }}</h4>
          <div class="pt-15">
            <span
              :class="is_exclusive ? 'color-white pl-2' : 'c-grey pl-2'"
              @click="onQuantityChange(-1)"
              >-</span
            >
            <span
              :class="is_exclusive ? 'color-white number' : 'c-black number'"
              >{{ cartItem.quantity }}x</span
            >
            <span
              :class="is_exclusive ? 'color-white' : 'c-grey'"
              @click="onQuantityChange(1)"
              >+</span
            >
          </div>
        </div>
        <div class="w-50 d-flex justify-content-end">
          <h5 class="d-flex align-items-baseline align-self-start">
            {{ $t("list.total") }}
            <h2>{{ uiTotal }}{{currency.symbol}}</h2>
          </h5>
        </div>
      </div>
      <div class="d-flex justify-content-between qnty-total-footer">
        <img src="/icons/close.svg" class="close" @click="hideModal()" />
        <button class="add-2-cart" @click="onAddToCart">
          {{ $t("menu.add_to_cart") }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import RemoteScroller from "@/components/RemoteScroller.vue";

export default {
  props: {
    product: Object,
    is_exclusive: Boolean,
  },
  data() {
    return {
      selectedVariant: null,
      selectedModificators: null,
      cartItem: {
        name: "n/a",
        category: "n/a",
        variant: "n/a",
        variants: [],
        price: 0,
        quantity: 1,
        modificators: [],
        available: true,
        weight: 0,
        unit: "g",
        weighted: false,
      },
    };
  },
  created() {
    this.toggleGlobalScrolling(true, true);
  },
  mounted() {
    this.resetCartItem();
  },
  methods: {
    hideModal() {
      this.toggleGlobalScrolling(false);
      this.$emit("hideModal");
    },
    resetCartItem() {
      this.cartItem.name = this.product.name;
      this.cartItem.category = this.product.category;
      this.cartItem.variants = [];
      this.cartItem.quantity = 1;
      this.cartItem.modificators = [];
      this.cartItem.available = true;
      this.cartItem.weight = this.product.weight || 0;
      this.cartItem.unit = this.product.unit || "g";
      this.cartItem.weighted = this.product.weighted || false;

      this.product.price_groups.forEach(g => {
        if (g.single_select) this.onSelectVariant(g.items[0], g);
      });
    },
    onSelectVariant(v, group) {
      group.items.forEach(it => {
        this.cartItem.variants = this.cartItem.variants.filter(
          fv => fv.name != it.name && fv.price != it.price
        );
      });

      this.cartItem.variants.push(v);
      // // this.selectedVariant = v;
      // this.cartItem.variant = this.selectedVariant.variant;
      // this.cartItem.price = this.selectedVariant.value;
    },
    getVariantClass(variant) {
      return {
        item: true,
        active: !!this.cartItem.variants.find(v => v.name == variant.name && v.price == variant.price)
      };
    },
    onSelectModificator(target_modificator) {
      const index = this.cartItem.modificators.findIndex(
        (m) => m.name == target_modificator.name
      );

      if (index == -1) {
        this.cartItem.modificators.push(target_modificator);
      } else {
        this.cartItem.modificators.splice(index, 1);
      }
    },
    getModificatorClass(modificator) {
      const index = this.cartItem.modificators.findIndex(
        (m) => m.name == modificator.name
      );

      return {
        item: true,
        active: index != -1,
      };
    },
    onQuantityChange(delta) {
      const new_quantity = this.cartItem.quantity + delta;
      if (new_quantity <= 0 || new_quantity > 30) return;

      this.cartItem.quantity += delta;
    },
    onAddToCart() {
      this.cartItem.price = this.variantsPrice;
      this.cartItem.variant = this.cartItem.variants.map(v => v.name).join(',');
      this.$store.dispatch("cart/addItem", Object.assign({}, this.cartItem));
      this.hideModal();
    },
  },
  computed: {
    variantsPrice() {
      let initial_price = this.product.base_price;

      initial_price += this.cartItem.variants.reduce((acc,v) => acc +v.price, 0);

      return initial_price;
    },
    uiTotal() {
      // if (!this.selectedVariant) return 0;

      let initial_price = this.variantsPrice;


      if (this.cartItem.modificators && this.cartItem.modificators.length) {
        initial_price += this.cartItem.modificators.reduce(
          (acc, mod) => acc + mod.price,
          0
        );
      }

      return initial_price * this.cartItem.quantity;
    },
    currency() {
      return this.$store.state.currency.current;
    },
  },
  components: {
    RemoteScroller,
  },
};
</script>  

<style scoped>
* {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

.pt-15 {
  line-height: normal;
  padding-top: 1.7vh;
}

.c-grey {
  color: #5a5a5a;
}

.c-black {
  color: #1d1d1b;
}

img.close {
  height: 3vh;
  width: 3vh;
  opacity: 1;
  cursor: pointer;
}

.qnty-total {
  display: flex;
  justify-content: space-between;
}

.qnty-total-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5.5vh 3.3vh 3.3vh;
}

.qnty-total span {
  cursor: pointer;
  font-size: 3vh;
  padding-right: 4.4vh;
}

.qnty-total span.number {
  font-size: 2.7vh;
}

.item span {
  color: #a4a3a1;
  line-height: initial;
  font-size: 2.2vh;
  position: absolute;
  right: 2vh;
}

.qnty-total h5 {
  float: right;
  font-size: 2.3vh;
  color: #a4a3a1;
}

h2 {
  color: #a4a3a1;
  padding-left: 2.2vh;
  font-size: 5.5vh;
}

hr {
  border-top: 1px solid #a4a3a1;
}

h4 {
  font-size: 2vh;
  padding-left: 2.2vh;
}

.add-2-cart {
  background: #129b81;
  color: white;
  width: auto;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 1vh;
  padding: 1.8vh 3.5vh;
  font-size: 2.1vh;
  transition: 0.3s;
  outline: none;
  border: none;
}

.add-2-cart:active {
  box-shadow: none;
  transform: translateY(0.5vh);
}

.variants,
.additions {
  padding: 2.2vh 0 2.2vh 3.3vh;
  position: relative;
}

.qnty-total {
  padding: 3.3vh 3.3vh 0;
}

.item h4 {
  padding: 0;
  display: block;
  max-width: 21vh;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item {
  background: #ededed;
  box-shadow: 0px 1px 7px #dadada;
  border-radius: 1vh;
  position: relative;
  border: 2px solid transparent;
  min-width: 31.6vh;
  margin-top: 1.7vh;
  margin-bottom: 1vh;
  margin-right: 2.2vh;
  font-size: 1.7vh;
  padding: 1.4vh 2vh;
  cursor: pointer;
}

.item:first-child {
  margin-left: 0.5vh;
}

.variants .item.active {
  background: #129b81;
  color: #ededed;
  box-shadow: 0px 1px 7px #747474;
}

.variants .item.active span {
  color: #ededed;
}

.additions .item.active {
  border: 2px solid #129b81;
}

.dish-name {
  font-size: 3.1vh;
  line-height: normal;
}

.category-name {
  font-size: 1.5vh;
}

.category-name,
.description {
  color: #a4a3a1;
}

.description {
  font-size: 2vh;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-top: 2.2vh;
}

.blur-content {
  z-index: 2;
  height: 100vh;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}
.modal-box {
  animation: popup 0.3s;
  z-index: 3;
  background: #ededed;
  max-height: 88vh;
  overflow: auto;
  width: 83vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  top: 53vh;
  transform: translateY(-50%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2vh;
}

.modal-box.black {
  background: #1d1d1b;
}

@keyframes popup {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  50% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

.modal-space {
  padding: 3.3vh;
}

.info {
  padding-right: 2.2vh;
  width: 100%;
  position: relative;
}

.dish-pic {
  position: relative;
  float: right;
  height: 21vh;
  width: 79.3vh;
}

.dish-pic img {
  object-fit: cover;
  border-radius: 1vh;
  height: 100%;
  width: 100%;
}

.variants-remote-scroller {
  position: absolute;
  top: 2.8vh;
  right: 3.4vh;

  width: 38.3vh;
}

.variants,
.item {
  user-select: none;
}
</style>