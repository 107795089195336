<template>
  <div v-thematic class="order-menu">
    <MenuSwitcher
      :menus="nonEmptyMenus"
      :current="menu_index"
      :showServices="showServices"
      @servicesClicked="$emit('servicesClicked')"
      @specialServiceButtonClicked="onSpecialServiceButtonClicked"
      :specialServiceButton="cafe._id == 462 || cafe._id == 3"
      :call_waiter_timeout="call_waiter_timeout"
      @switch="propogateMenuSwitch"
      v-show="canShowMenuSwitcher"
      v-if="isMobile"
    />
    <MenuSwitcherDesktop
      v-else
      :menus="nonEmptyMenus"
      :current="menu_index"
      @switch="propogateMenuSwitch"
      v-show="canShowMenuSwitcher"
    />
    <div
      class="menu-switcher-padding"
      v-show="!canShowMenuSwitcher && currentView == 'categories' && isMobile"
    ></div>

    <CategoryList
      @select="onCategorySelect"
      @selectProduct="onProductSelect"
      @selectExclusive="onExclusiveSelect"
      :categories="menu.categories"
      :products="menu.products"
      :exclusives="exclusivesList"
      :target="target"
      v-if="currentView === 'categories' && isMobile"
    />
    <CategoryListDesktop
      v-else-if="!isMobile"
      @select="onCategorySelect"
      @selectProduct="onProductSelect"
      @selectExclusive="onExclusiveSelect"
      :categories="menu.categories"
      :products="menu.products"
      :exclusives="exclusivesList"
      :target="target"
      :cafe="cafe"
      :is_cart_active="is_cart_active"
      :isMenuSwitcherShown="canShowMenuSwitcher"
      :cart="cart"
      ref="categoryListDesktop"
    />

    <!-- <ProductList
      @select="onProductSelect"
      :products="categoryItems"
      :category="selectedCategory"
      v-if="currentView === 'products'"
    /> -->

    <ProductDetails
      :product="selectedProduct"
      :cart="cart"
      :recommendations="currentRecommendations"
      :is_exclusive="is_exclusive"
      :target="target"
      :menu="menu"
      :disable_cart="disable_cart"
      @add="onAddToCart"
      @addedToCart="commitRecommendationPair"
      @recommendationSelect="onRecommendationSelect"
      v-if="isMobile && currentView === 'details'"
    />
    <DishPopupDesktop
      :product="selectedProduct"
      :recommendations="currentRecommendations"
      :menu="menu"
      @selectRecommendation="onRecommendationSelect"
      @close="
        selectedProduct = null;
        currentView = 'products';
      "
      :disableCart="disable_cart"
      v-if="!isMobile && currentView == 'details' && selectedProduct"
    />

    <!-- <DesktopProductModal
      :is_exclusive="is_exclusive"
      :product="selectedProduct"
      @hideModal="closeModal()"
      v-if="!hideModal && !isMobile && currentView == 'details'"
    /> -->

    <!-- <RotateScreenModal /> -->

    <OrderButtons
      mode="menu"
      @back="onBackArrowPressed"
      @switchView="propogateSwitchView"
      :cartLocked="cartLocked"
      :amount="cart.length"
      :target="target"
      :showArrow="showBackArrow"
      v-show="cartLocked || (!disable_cart && cart.length)"
    />
  </div>
</template>

<script>
import CategoryList from "@/components/menu/CategoryList";
import AIModal from "@/components/menu/AIModal";
import ProductList from "@/components/menu/ProductList";
import ProductDetails from "@/components/menu/ProductDetails";
import MenuSwitcher from "@/components/menu/MenuSwitcher";
import DesktopProductModal from "@/components/menu/DesktopProductModal";
import RotateScreenModal from "@/components/modals/RotateScreenModal";

import MarketplaceService from "@/services/marketplace";

import OrderButtons from "@/components/OrderButtons";

import Recommendations from "@/services/recommendations";

import TelemetryService from "@/services/telemetry";

import RecommendationsAPI from "@/api/recommendations";

//desktop components
import CategoryListDesktop from "@/components/desktop/CategoryListDesktop";
import MenuSwitcherDesktop from "@/components/desktop/MenuSwitcherDesktop";
import DishPopupDesktop from "@/components/desktop/DishPopupDesktop";

import OrderAPI from "@/api/order";

export default {
  data() {
    return {
      currentView: "categories",

      selectedCategory: null,
      selectedProduct: null,

      is_exclusive: false,
      showAIModal: true,
      currentRecommendations: [],
      hideModal: false,
      showOrderCart: false,

      recommendation_staged_item: null,

      call_waiter_timeout: 0
    };
  },
  watch: {
    currentView() {
      this.$emit("menuViewChange", this.currentView);
    },
  },
  props: {
    is_cart_active: {
      type: Boolean,
      default: false,
    },
    cafe: Object,
    menu: Object,
    cart: Array,
    target: Object,
    tagged_menus: Array,
    menu_index: Number,
    cartLocked: Boolean,
    showServices: Boolean,
    disable_cart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CategoryList,
    ProductList,
    ProductDetails,
    OrderButtons,
    MenuSwitcher,
    AIModal,
    RotateScreenModal,
    DesktopProductModal,
    MenuSwitcherDesktop,
    CategoryListDesktop,
    DishPopupDesktop,
  },
  methods: {
    openCart() {
      this.$refs.categoryListDesktop.openCart();
    },
    onDesktopCartOpened() {
      this.selectedProduct = null;
    },
    onCategorySelect(category) {
      this.selectedCategory = category;
      this.currentView = "products";

      TelemetryService.emit(
        TelemetryService.Events.ViewCategory,
        category.name
      );
      this.$saveScroll("menu_categories");
      this.resetScroll();
    },
    fetchServerRecommendations(product) {
      RecommendationsAPI.fetch(
        this.tagged_menus.map((m) => m._id),
        product._id
      ).then((data) => {
        this.currentRecommendations = data.recommendations
          .map((item) => {
            const menu = this.tagged_menus.find((m) => m._id == item.menu_id);

            if (menu) {
              return menu.products.find((p) => p._id == item.product_id);
            }
          })
          .filter((p) => !!p);

        if (this.currentRecommendations.length < 3) {
          this.currentRecommendations = this.currentRecommendations.concat(
            Recommendations.getForProduct(
              product,
              this.menu.products,
              this.currentRecommendations
            ).slice(0, 3 - this.currentRecommendations.length)
          );
        }
      });
    },
    onProductSelect(product) {
      // this.currentRecommendations = Recommendations.getForProduct(
      //   product,
      //   this.menu.products
      // );

      this.fetchServerRecommendations(product);

      this.selectedCategory = product.category;
      this.selectedProduct = product;
      this.currentView = "details";
      this.hideModal = false;
      TelemetryService.emit(TelemetryService.Events.ViewProduct, product.name);
      TelemetryService.addMilestone("menu");
      this.is_exclusive = false;

      this.$saveScroll("menu_categories");
      if (this.isMobile) this.resetScroll();
    },
    onExclusiveSelect(product) {
      this.onProductSelect(product);
      this.is_exclusive = true;
    },
    onRecommendationSelect(product) {
      this.onProductSelect(product);

      TelemetryService.emit(
        TelemetryService.Events.ClickRecommendation,
        product.name
      );
    },
    onBackArrowPressed() {
      if (this.currentView == "categories") {
        if (MarketplaceService.get().flag) {
          if (
            this.cafe.modes.preorder +
              this.cafe.modes.delivery +
              this.cafe.modes.reserve >
            1
          ) {
            this.$router.push({
              name: "choose_mode",
              params: {
                code: this.cafe.online_menu_link,
              },
              query: {
                returning: "1",
              },
            });
            return;
          }

          this.$router.push({
            name: "marketplace",
            query: {
              skip: true,
              mode: this.$route.name,
            },
          });
          // this.$router.go(-1);
        } else {
          this.$router.push({
            name: "choose_mode",
            params: {
              code: this.cafe.online_menu_link,
            },
          });
        }

        return;
      }
      this.currentView = "categories";
      this.$restoreScroll("menu_categories");
      return;
    },
    propogateSwitchView(arg) {
      this.$emit("switchView", arg);
    },
    propogateMenuSwitch(index) {
      this.$emit("switchMenu", index);
    },
    onAddToCart(item) {
      this.$emit("buy", item);

      TelemetryService.emit(
        TelemetryService.Events.BuyProduct,
        `${item.name} x${item.quantity}`
      );
    },
    onNativeBackButtonPressed() {
      if (this.currentView != "categories") this.onBackArrowPressed();
    },
    closeModal() {
      this.hideModal = true;
    },
    showOrderModal() {
      this.showOrderCart = true;
    },
    closeAI() {
      this.showAIModal = false;
    },
    commitRecommendationPair(product) {
      if (!this.recommendation_staged_item) {
        this.recommendation_staged_item = {
          product_id: product._id,
          menu_id: this.menu._id,
        };
        return;
      }

      const commit_data = {
        left_menu_id: this.recommendation_staged_item.menu_id,
        left_product_id: this.recommendation_staged_item.product_id,
        right_menu_id: this.menu._id,
        right_product_id: product._id,
        type: "pair",
      };

      RecommendationsAPI.commit(commit_data).catch((error) => {
        console.error(`Recommendations commit failed: ${error}`);
      });

      this.recommendation_staged_item = {
        product_id: product._id,
        menu_id: this.menu._id,
      };
    },
    tickCallWaiterTimeout() {
      this.call_waiter_timeout--;

      if (this.call_waiter_timeout > 0) setTimeout(this.tickCallWaiterTimeout, 1000);
    },
    onSpecialServiceButtonClicked() {
      if (this.call_waiter_timeout > 0) return;
      
      OrderAPI.callService(this.target, 'Call waiter', {
        lat: 1.2796475,
        lon: 103.8405486
      })
        .then((data) => {
          this.call_waiter_timeout = 60;

          this.tickCallWaiterTimeout();
        })
        .catch((error) => {
          //alert(error);
        });
    },
  },
  mounted() {
    window.document.body.style.backgroundColor = this.isDarkMode
      ? "black"
      : "#ededed";
  },
  computed: {
    categoryItems() {
      return this.menu.products.filter(
        (p) => p.category_id === this.selectedCategory._id
      );
    },
    canShowMenuSwitcher() {
      return (
        (!this.isMobile || this.currentView === "categories") &&
        this.nonEmptyMenus &&
        (this.nonEmptyMenus.length > 1 || this.showServices)
      );
    },
    exclusivesList() {
      if (!this.menu.show_exclusives) return [];

      let real_exclusives = this.menu.products.filter(
        (p) => p.is_exclusive && p.stock
      );
      let non_exclusives = this.menu.products.filter(
        (p) => !p.is_exclusive && p.stock
      );

      if (real_exclusives.length) {
        real_exclusives = Recommendations.shuffle(real_exclusives);
      }

      let result = real_exclusives.slice();

      if (result.length < 10 && this.cafe._id != 126) {
        const to_fill = 10 - result.length;
        result = result.concat(
          Recommendations.getTopProducts(non_exclusives, to_fill)
        );
      }

      return result;
    },
    nonEmptyMenus() {
      return this.tagged_menus.filter((m) => {
        return !!m.products && m.products.length;
      });
    },
    showBackArrow() {
      if (this.currentView != "categories") return true;

      if (
        this.$route.name == "preorder" ||
        this.$route.name == "delivery" ||
        this.$route.name == "reserve"
      ) {
        const modes_active =
          +this.cafe.modes.preorder +
          this.cafe.modes.delivery +
          this.cafe.modes.reserve;

        return modes_active > 1 || MarketplaceService.get().flag;
      }

      return this.currentView != "categories";
    },
  },
};
</script>

<style scoped>
.order-menu {
  margin: 7.4vh 0 12vh 0;
  background: #ededed;
}
/* 
.order-menu.space {
  margin-top:13.5vh
} */

.order-menu.dark {
  background: black;
}

.menu-switcher-padding {
  width: 100%;
  height: 3vh;
}

@media screen and (min-width: 1024px) {
  .order-menu {
    margin-bottom: 0;
    overflow: hidden;
  }
}
</style>
