<template>
  <div class="product-list">
    <h4 v-thematic>{{ categoryDisplayName }}</h4>
    <div
      class="item-dishes"
      v-for="product in availableProducts"
      :key="product._id"
      @click="select(product)"
    >
      <div class="img-container">
        <img class="img-dish" :src="getProductImage(product)" v-if="product.image" />

        <img
          class="img-placeholder"
          src="/icons/no-dish.jpg"
          alt="Dish Image Unknown"
          v-if="!product.image"
        />
      </div>
      <div class="dish-info">
        <p :class="isDarkMode ? 'dark':'color-black'">{{ getProductDisplayName(product) }}</p>
        <!-- <ul v-if="product.description && product.description.length">
          <li
            class="color-grey"
            v-for="ingr in makeIngridientsList(product.description)"
          >{{ ingr.trim() }}</li>
        </ul> -->
      </div>
      <!-- <span class="waiting-time" v-if="product.waiting_time">
        <font-awesome-icon class="timer" :icon="['fas', 'clock']" />
        {{product.waiting_time}} {{ $t('units.minutes') }}
      </span>-->
      <span :class="{
        price: true,
        'color-grey': true,
        'price-discount': !!promotionDiscount(product)
        }" v-thematic v-if="getProductPrice(product)">{{ getProductPrice(product) }}{{currency.symbol}}</span>
    </div>
  </div>
</template>

<style scoped>
.additions {
  margin-right: -45px;
  font-weight: 300;
  font-size: 15px;
}

.item-dishes {
  animation: slide-top 0.3s;
  width: 100%;
  display: flex;
  margin-top: 1.9vh;
  padding: 0;
}

@keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.img-container {
  min-width: 105px;
  min-height: 100px;

  width: 105px;
  height: 100px;
}

.img-dish {
  width: 105px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.img-placeholder {
  width: 105px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  color: #bcbcbc;
}

.item-dishes ul {
  padding-left: 0;
  margin: -0.5vh 0 0 0;
  font-weight: 100;
  height: 6.5vh;
  overflow: hidden;
}

.dish-info {
  padding: 0 4vw;
  width: 100%;
}

.dish-info li {
  max-width: 40vw;
  overflow: hidden;
  display: -webkit-box;
  line-height: 2.2vh;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dish-info p {
  font-size: 2.3vh;
  white-space: normal;
  line-height: normal;
  max-width: 19vh;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-bottom: 0;
}

.dish-info p.dark {
  color: #ededed;
}

.price {
  display: flex;
  align-items: flex-start;
  font-size: 7vw;
  font-weight: 500;
  padding: 0;
  margin-top: -1vh;
}

.price.dark {
  color: #908f8d;
}

.waiting-time {
  display: flex;
  right: 20px;
  font-size: 14px;
  color: #bcbcbc;
  position: absolute;
}

.timer {
  margin: 2px;
}

.product-list {
  padding: 0 5.4vw;
  margin-top: 1.3vh;
}

h4 {
  padding: 0;
  font-size: 7vw;
  font-weight: 500;
  text-align: center;
  margin: 1vh 0 0 0;
  color: #262626;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

h4.dark {
  color: #a4a3a1;
}

.price-discount {
  color: #129b81 !important;
}
</style>

<script>
export default {
  data() {
    return {};
  },
  props: {
    products: Array,
    category: String
  },
  methods: {
    select(p) {
      this.$emit("select", p);
    },
    getProductPrice(p) {
      const base_price = p.base_price || 0;

      const variants_group = p.price_groups.find(g => g.single_select);

      if (variants_group && variants_group.items[0] && variants_group.items[0].price) {
        return variants_group.items[0].price + base_price;
      }

      const discount = this.promotionDiscount(
        p
      );

      if (discount) {
        return Math.round(base_price * discount);
      }

      return base_price;
    },
    getProductDisplayName(product) {
      return this.$store.getters["menu/localize"]("products", product.name);
    },
    makeIngridientsList(base) {
      return this.$store.getters["menu/localize"]("ingridients", base)
        .split(",")
        .slice(0, 3);
    },
    promotionDiscount(product) {
      return this.$store.getters['promotions/getProductDiscount'](product);
    },
    getProductImage(product) {
      if (product.image.includes('joinposter.com')) {
        return product.image.replace('_original', '');
      }
      
      return product.image;
    }
  },
  computed: {
    availableProducts() {
      return this.products.filter(p => !!p.stock);
    },
    categoryDisplayName() {
      return this.$store.getters["menu/localize"]("categories", this.category);
    },
    currency() {
      return this.$store.state.currency.current;
    }
  }
};
</script>