<template>
  <div>
    <div class="special-card">
      <img
        :src="productImage"
        :alt="`${product.name} - Image`"
      />
      <!-- <div class="gradient-bg"> -->
        <div :class="displayName.length > 15 ? 'gradient-bg wide':'gradient-bg'">
        <div class="info">
          <div class="d-flex justify-content-between">
            <h2 class="color-white">{{ displayName }}</h2>
            <span class="color-white" v-if="firstPrice"
              >{{ firstPrice }}{{ currency.symbol }}</span
            >
          </div>
          <!-- <p class="color-white">{{ displayDescription }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gradient-bg {
  position: absolute;
  /* height: auto; */
  height: 8vh;
  width: 100%;
  bottom: 0;
  background: rgba(29, 29, 27, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 2vh;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wide {
  /* height: 12vh; */
}

.special-card {
  cursor: pointer;
  position: relative;
  width: 72vw;
  height: 33.3vh;
  margin: 0 0 0 2.7vh;
  border-radius: 2vh;
}

.special-card img {
  border-radius: 2vh;
  max-width: 100%;
  max-height: 100%;

  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.info {
  color: white;
  /* position: relative; */
  position: absolute;
  display: inline-block;
  justify-content: space-between;
  bottom: 0;
  padding: 2.2vh;
  width: 100%;
  border-radius: 12px;
}
span {
  float: right;
  /* display: flex; */
  /* align-items: center; */
  font-weight: 500;
  line-height: normal;
  font-size: 3.5vh;
}
h2 {
  font-size: 3.2vh;
  max-width: 79%;
  font-weight: 500;
  display: inline-block;
  /* display: -webkit-box; */
/* -webkit-line-clamp: 2;
-webkit-box-orient: vertical; */
  margin-bottom:0;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}

p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 5vw;
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .special-card {
    width: 77vh;
    height: 33.4vh;
    margin: 0;
  }
  .gradient-bg {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 51.04%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  h2 {
    font-size: 3.1vh;
    margin-bottom: 0;
    max-width: 55vh;
  }
  .info {
    padding: 3.3vh 5.5vh;
  }
  p {
    font-size: 2vh;
    padding-top: 1.7vh;
  }
  span {
    padding-right: 0;
    font-size: 3.4vh;
    /* margin-top: -3.2vh; */
    line-height: 0.8;
    position: relative;
    float: right;
  }
  p {
    line-height: 1;
  }
}
</style>

<script>
export default {
  props: {
    product: Object,
  },
  computed: {
    displayName() {
      return this.$store.getters["menu/localize"](
        "products",
        this.product.name
      );
    },

    displayDescription() {
      return this.$store.getters["menu/localize"](
        "descriptions",
        this.product.description
      );
    },

    currency() {
      return this.$store.state.currency.current;
    },

    firstPrice() {
      const base_price = this.product.base_price || 0;

      const variants_groups = this.product.price_groups.filter(
        (g) => g.single_select
      );

      let variants_add = 0;

      for (let i = 0; i < variants_groups.length; i++) {
        const items = variants_groups[i].items;

        variants_add += items[0].price;
      }

      // if (variants_group && variants_group.items[0] && variants_group.items[0].price) {
      //   return variants_group.items[0].price + base_price;
      // }

      return base_price + variants_add;
    },
    productImage() {
      if (!this.product.image) {
        return "/icons/no-dish-exclusive.jpg";
      }

      if (this.product.image.includes("joinposter.com")) {
        return this.product.image.replace("_original", "");
      }

      return this.product.image;
    },
  },
};
</script>