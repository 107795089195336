<template>
  <div>
    <div :class="is_exclusive ? 'img-block' : 'img-regular'">
      <img
        :src="productImage"
        alt="product image"
        :class="is_exclusive ? 'img-exclusive' : 'img-description'"
        v-if="product.image"
      />

      <img
        :src="
          is_exclusive ? '/icons/no-dish-exclusive.jpg' : '/icons/no-dish.jpg'
        "
        alt="product image"
        :class="is_exclusive ? 'img-exclusive' : 'img-description'"
        v-if="!product.image"
      />
      <!-- <div :class="is_exclusive ? 'gradient-bg' : ''">
      </div>-->

      <ARButton :product="product" class="ar-icon" />
    </div>

    <div :class="[!hideContent ? 'info' : 'info no-space']">
      <div
        :class="[
          is_exclusive ? 'product-details lux-details' : 'product-details',
        ]"
        v-thematic
      >
        <!-- <div class="lux-title color-white" v-if="is_exclusive">
          <h2>{{ productDisplayName }}</h2>
          <p v-html="productDisplayDescription"></p>
        </div>-->

        <h4
          :class="[is_exclusive ? 'm-0 color-black' : 'm-0 color-black']"
          v-thematic
        >
          {{ productDisplayName }}
        </h4>
        <span
          :class="{
            price: true,
            'price-discount': !!promotionDiscount,
          }"
          v-thematic
          v-if="uiTotalPrice"
          >{{ uiTotalPrice }}{{ currency.symbol }}</span
        >

        <!--<p class="ingridients" v-if="product.ingridients">{{ productDisplayIngridients }}</p>-->
        <p
          class="ingridients сolor-grey"
          ref="descriptionElement"
          v-thematic
          v-show="!hideContent && product.description"
          v-html="productDisplayDescription"
        ></p>

        <span
          class="ingridients show-more"
          v-thematic
          v-if="can_expand_description && !hideContent && !expanded_description"
          @click="
            expanded_description = true;
            can_expand_description = false;
          "
        >
          {{ $t("menu.show_more") }}
        </span>

        <!-- <p
          class="ingridients сolor-grey"
          v-if="!hideContent && product.ingredients && product.ingredients.length"
        >
          {{ product.ingredients.join(', ')}}
        </p>-->

        <div v-if="totalWeight || product.waiting_time" class="misc-params">
          <img src="../../assets/mass.svg" class="icon" v-if="totalWeight" />
          <p
            :class="
              isDarkMode ? 'weight color-white mb-0' : 'weight color-black mb-0'
            "
            v-if="totalWeight"
          >
            {{ totalWeightDisplayText }}
          </p>

          <img
            src="../../assets/timer-dark.svg"
            class="icon"
            v-if="product.waiting_time"
          />
          <p class="waiting-time color-grey" v-if="product.waiting_time">
            {{ product.waiting_time }} {{ $t("units.minutes") }}
          </p>

          <p class="waiting-time mb-0" v-if="pricePerUnit">
            {{
              $t("menu.price_per_unit", {
                unit: pricePerUnit.unit,
              })
            }}
            {{ pricePerUnit.price }} {{ currency.symbol }}
          </p>
        </div>
        <!--
        <div class="variants" v-if="product.prices && product.prices.length > 1 && !hideContent">
          <h6>{{ $t('menu.choose_variant') }}</h6>

          <div class="variants-block">
            <ul
              :class="getVariantClass(v)"
              v-thematic
              v-for="v in product.prices"
              :key="v.variant"
              @click="selectedVariant = v"
            >
              <li :class="is_exclusive ? 'extra-space':''">
                {{ getVariantDisplayName(v.variant) }}
                 <span
                  @click="selectedVariant = v"
                  :class="selectedVariant ? 'active':'' "
                  v-if="v.value > 0"
                >{{v.value}}{{currency.symbol}}</span>
              </li>
            </ul>
          </div>
        </div> -->

        <div class="price-groups" v-if="!hideContent && !isOnlyOnePrice">
          <div
            class="price-group"
            v-for="(price_group, index) in product.price_groups || []"
            :key="index"
          >
            <div v-if="price_group.single_select">
              <VariantsGroup
                :group="price_group"
                :dish="product"
                @add="(v) => cartItem.variants.push(v)"
                @remove="(i) => cartItem.variants.splice(i, 1)"
                @removeModificator="(i) => cartItem.modificators.splice(i, 1)"
                :selected="cartItem.variants"
                :selectedModificators="cartItem.modificators"
                :show_prices="isMultipleVariants"
                :forceNoCheck="target.cafe_id == 318"
                v-if="canShowPriceGroup(price_group)"
              />
            </div>
            <div v-else>
              <ModificatorsGroup
                :group="price_group"
                :selected="cartItem.modificators"
                @toggle="onModificatorToggle"
                v-if="canShowPriceGroup(price_group)"
              />
            </div>
          </div>
        </div>

        <!-- <div v-if="product.modificators && validModificators.length > 0 && !hideContent">
          <h5
            :class="[is_exclusive ? 'modif modif-white': 'modif']"
            v-thematic
            @click="toggleModif()"
          >
            {{ $t('menu.choose_modificators') }}
            <img
              :class="!showModificators ? 'arrow1':'arrow2'"
              src="/icons/list-arrow.svg"
            />
          </h5>

          <div class="modif-list modif-block" v-if="showModificators">
            <div
              v-for="mod in validModificators"
              :key="mod.name"
              :class="getModificatorClass(mod)"
              v-thematic
              @click="onModificatorToggle(mod)"
            >
              <p class="modif-title">
                {{ getModificatorDisplayName(mod.name )}}
                <span
                  v-if="mod.weight"
                >({{ mod.weight}} {{ $t(`units.${product.unit}`)}})</span>
              </p>
              <span v-if="mod.price > 0" class="float-right">+{{mod.price}}{{currency.symbol}}</span>
            </div>
          </div>
        </div> -->

        <div class="quantity" v-if="canAddToCart">
          <img
            v-if="isDarkMode"
            src="/icons/btn-minus.svg"
            @click="changeQuantity(-1)"
            alt
            :style="{
              visibility: cartItem.quantity > 1 ? 'visible' : 'hidden',
            }"
          />
          <img
            v-else
            src="/icons/btn-white-minus.svg"
            @click="changeQuantity(-1)"
            alt
            :style="{
              visibility: cartItem.quantity > 1 ? 'visible' : 'hidden',
            }"
          />

          <span v-thematic>{{ quantityDisplayText }}</span>

          <img
            v-if="isDarkMode"
            src="/icons/btn-plus.svg"
            @click="changeQuantity(1)"
            alt
            :style="{
              visibility: hasEnoughProductInStock ? 'visible' : 'hidden',
            }"
          />

          <img
            v-else
            src="/icons/btn-white-plus.svg"
            @click="changeQuantity(1)"
            alt
            :style="{
              visibility: hasEnoughProductInStock ? 'visible' : 'hidden',
            }"
          />
        </div>

        <button
          :class="
            is_exclusive ? 'add-to-cart lux-add' : 'add-to-cart color-white'
          "
          @click="addToCart"
          v-if="canAddToCart"
        >
          {{ $t("menu.add_to_cart") }}
          <span class="color-white" v-if="uiTotalPrice"
            >{{ uiTotalPrice }}{{ currency.symbol }}</span
          >
        </button>

        <!-- <div class="menu-closed-block" v-if="menuClosedWay > 0">
          {{ $t("menu.menu_closed_before") }} {{ menuClosedTime }}
        </div>

        <div class="menu-closed-block" v-if="menuClosedWay < 0">
          {{ $t("menu.menu_closed_after") }} {{ menuClosedTime }}
        </div> -->

        <p v-if="hideContent" class="added2" v-thematic>
          <img src="/icons/ok.png" />
          {{ $t("menu.added_to_cart") }}
        </p>
        <div class="closed-bar" v-thematic v-if="menuClosedWay > 0">
          <img src="/icons/warning.svg" />
          <p>{{ $t("menu.menu_closed_before") }} {{ menuClosedTime }}</p>
        </div>
        <div class="closed-bar" v-thematic v-if="menuClosedWay < 0">
          <img src="/icons/warning.svg" />
          <p>{{ $t("menu.menu_closed_after") }} {{ menuClosedTime }}</p>
        </div>
        <div class="closed-bar" v-thematic v-if="!resultProductStock">
          <img src="/icons/warning.svg" />
          <p>
            {{ $t("menu.out_of_stock") }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="reccomendations"
      v-thematic
      v-if="!target.lite && recommendations.length"
    >
      <ProductList
        :products="recommendations"
        :category="$t('menu.recommending')"
        @select="emitRecommendationSelect"
      />
      <!-- <img src="/icons/grey-logo.svg" alt="logo" class="mx-auto mt-5 d-block" /> -->
      <SupportIcon />
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/menu/ProductList.vue";

import ModificatorsGroup from "@/components/menu/ModificatorsGroup.vue";
import VariantsGroup from "@/components/menu/VariantsGroup.vue";

import SupportIcon from "@/components/SupportIcon.vue";

import ARButton from "@/components/menu/ARButton.vue";

import moment from "moment";

function getLineHeight(el) {
  var temp = document.createElement(el.nodeName),
    ret;
  temp.setAttribute(
    "style",
    "margin:0; padding:0; " +
      "font-family:" +
      (el.style.fontFamily || "inherit") +
      "; " +
      "font-size:" +
      (el.style.fontSize || "inherit")
  );
  temp.innerHTML = "A";

  el.parentNode.appendChild(temp);
  ret = temp.clientHeight;
  temp.parentNode.removeChild(temp);

  return ret;
}

export default {
  data() {
    return {
      cartItem: {
        name: "n/a",
        category: "n/a",
        variant: "n/a",
        price: 0,
        quantity: 1,
        variants: [],
        modificators: [],
        available: true,
        weight: 0,
        unit: "g",
        weighted: false,
        menu_id: null,
        product_id: null,
      },
      showModificators: false,
      selectedVariant: null,
      selectedMod: null,
      // activeVariant: false,
      hideContent: false,
      is_exclusive: true,

      must_shorten_description: false,
      expanded_description: true,
      can_expand_description: false,
      description_words_per_line: 1,
    };
  },
  props: {
    product: Object,
    recommendations: Array,
    target: Object,
    menu: Object,
    disable_cart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    product() {
      this.setCartItem();
      this.hideContent = false;
    },
  },
  methods: {
    changeQuantity(delta) {
      if (this.cartItem.quantity > this.specialQuantity && delta < 0) {
        this.cartItem.quantity += delta;
      }

      if (this.cartItem.quantity < 30 && delta > 0) {
        this.cartItem.quantity += delta;
      }
    },
    addToCart() {
      this.cartItem.variant = this.cartItem.variants
        .map((v) => {
          if (v.name) return v.name;

          if (v.weight) {
            return `${v.weight} ${this.$t("units." + this.product.unit)}`;
          }

          return null;
        })
        .join(", ");
      this.cartItem.price = this.totalPrice || 0;
      this.cartItem.weight = this.totalWeight;
      this.cartItem.initial_price = this.variantsPrice;
      this.cartItem.total_weight =
        this.product.weight_flag != 0
          ? this.cartItem.quantity * this.cartItem.weight
          : this.totalWeight;
      // this.showModificators = false;
      // this.product.modificators = 0;
      // this.$emit("add", Object.assign({}, this.cartItem));

      if (this.promotionDiscount) {
        this.cartItem.quantity = 1;
      }

      this.$store.dispatch("cart/addItem", Object.assign({}, this.cartItem));

      this.$store.dispatch("promotions/checkCart");

      this.$emit("addedToCart", this.product);

      this.hideContent = true;
    },
    getModificatorClass(m) {
      const cartMod =
        !this.target.lite &&
        this.cartItem.modificators.find((i) => i.name === m.name);

      if (cartMod) {
        return (
          "modif-item " +
          (this.is_exclusive ? "modif-black-active" : "modif-active")
        );
      } else {
        return this.is_exclusive ? "modif-item modif-black" : "modif-item";
      }
    },
    getVariantClass(v) {
      const base_class = this.is_exclusive
        ? "variant no-shadow variant-black"
        : "variant";
      const active_class = this.is_exclusive
        ? "variant-black-active"
        : "variant variant-active";

      if (!this.selectedVariant || this.target.lite) {
        return `${base_class}`;
      }

      if (this.selectedVariant.variant === v.variant) {
        // this.activeVariant = true;
        return `${base_class} ${active_class}`;
      }
      // this.activeVariant = false;
      return `${base_class}`;
    },
    emitRecommendationSelect(p) {
      this.$emit("recommendationSelect", p);
    },
    toggleModif() {
      this.showModificators = !this.showModificators;
    },
    setCartItem() {
      this.cartItem.name = this.product.name;
      this.cartItem.category = this.product.category;
      this.cartItem.price = this.product.base_price || 0;
      this.cartItem.variant = "";
      this.cartItem.waiting_time = this.product.waiting_time || 0;
      this.cartItem.modificators = [];
      this.cartItem.quantity = this.specialQuantity;
      this.cartItem.weight = this.product.base_weight || 0;
      this.cartItem.unit = this.product.unit || "g";
      this.cartItem.weighted = this.product.weighted || false;
      this.cartItem.is_excise = this.product.is_excise || false;
      this.cartItem.weight_flag = this.product.weight_flag || 0;
      this.cartItem.menu_id = this.menu._id;
      this.cartItem.product_id = this.product._id;
      this.cartItem.max_count =
        this.product.stock_count > 0 ? this.product.stock_count : 30;
      this.cartItem.variants = [];

      this.$nextTick(this.checkExpandingDescription);

      const copyVariantItem = (group, item) => {
        const copy = Object.assign({}, item);

        copy.item_id = item._id;
        copy.group_id = group._id;
        copy.group_name = group.name;

        return copy;
      };

      this.product.price_groups.forEach((group) => {
        if (!group.single_select) return;

        if (!group.show_for) {
          this.cartItem.variants.push(copyVariantItem(group, group.items[0]));
        } else {
          const possible_variant = this.cartItem.variants.filter(
            (v) => group.show_for.includes(v._id)
          );

          if (possible_variant) {
            this.cartItem.variants.push(copyVariantItem(group, group.items[0]));
          }
        }
      });

      // this.cartItem.variants = this.product.price_groups
      //   .filter((g) => g.single_select && !g.show_for)
      //   .map((g) => g.items[0])
      //   .slice();

      if (this.promotionDiscount) {
        this.cartItem.variants.forEach((v, vi) => {
          this.$set(
            this.cartItem.variants[vi],
            "discount_price",
            Math.round(this.promotionDiscount * v.price)
          );
        });
      }

      // this.selectedVariant = this.product.prices[0];
    },
    onModificatorToggle(m) {
      const cartModIndex = this.cartItem.modificators.findIndex(
        (i) => i.name === m.name
      );

      const mcopy = Object.assign({}, m);

      mcopy.item_id = m._id;

      if (cartModIndex === -1) {
        this.cartItem.modificators.push(mcopy);
      } else {
        this.cartItem.modificators.splice(cartModIndex, 1);
      }
    },
    getVariantDisplayName(variant) {
      return this.$store.getters["menu/localize"]("prices", variant);
    },
    getModificatorDisplayName(mod) {
      return this.$store.getters["menu/localize"]("modificators", mod);
    },
    canShowPriceGroup(group) {
      if (!group.items.length) return false;

      if (group.show_for && group.show_for.length) {
        return (
          !!this.cartItem.variants.find((v) =>
            group.show_for.includes(v._id)
          ) ||
          !!this.cartItem.modificators.find((v) =>
            group.show_for.includes(v._id)
          )
        );
      }

      return true;
    },
    checkExpandingDescription() {
      const el = this.$refs.descriptionElement;

      if (!el) return 0;

      if (!this.product.description) {
        this.expanded_description = false;
        this.can_expand_description = false;
        return;
      }

      const line_height = getLineHeight(el);

      const total_height = this.$refs.descriptionElement.getBoundingClientRect()
        .height;

      console.log(line_height, total_height);

      const lines_count = Math.round(total_height / line_height);

      this.must_shorten_description = lines_count >= 6;

      this.expanded_description = !this.must_shorten_description;

      this.can_expand_description = this.must_shorten_description;

      this.description_words_per_line =
        this.product.description.split(" ").length / lines_count;
    },
  },
  computed: {
    isInCart() {
      let flag = false;

      this.cart.forEach((item) => {
        if (flag) return;

        if (item.name !== this.cartItem.name) {
          return;
        }

        if (item.category !== this.cartItem.category) {
          return;
        }

        if (item.variant != this.selectedVariant.variant) {
          return;
        }

        if (this.cartItem.modificators.length != item.modificators.length) {
          return;
        }

        if (this.cartItem.modificators.length) {
          const cartMods = this.cartItem.modificators.slice();
          const itemMods = item.modificators.slice();

          for (let idx = 0; idx < cartMods.length; idx++) {
            if (cartMods[idx].name !== itemMods[idx].name) {
              return;
            }
          }
        }

        flag = true;
        return;
      });

      return flag;
    },
    variantsPrice() {
      let value =
        (this.product.base_price || 0) +
        this.cartItem.variants.reduce((acc, item) => acc + item.price, 0);

      return value;
    },
    totalPrice() {
      let value = this.variantsPrice;

      if (this.promotionDiscount)
        value = Math.round(this.promotionDiscount * value);

      return value;
    },
    uiTotalPrice() {
      let price = this.totalPrice;

      if (this.cartItem.modificators)
        this.cartItem.modificators.forEach((m) => {
          price += m.price;
        });
      if (this.cartItem.quantity > 1) {
        price *= this.cartItem.quantity;
      }

      return price;
    },

    productDisplayName() {
      return this.$store.getters["menu/localize"](
        "products",
        this.product.name
      );
    },

    productDisplayDescription() {
      if (!this.product.description) return "";

      const full_translated_desc = this.$store.getters["menu/localize"](
        "descriptions",
        this.product.description
      ).replace(/\n/g, "<br>");

      if (!this.expanded_description) {
        const all_words = full_translated_desc.split(" ");
        const total_words = all_words.length;
        const cut_words_count = this.description_words_per_line * 5;
        return all_words.slice(0, cut_words_count).join(" ") + "...";
      } else {
        return full_translated_desc;
      }
    },

    productDisplayIngridients() {
      return this.$store.getters["menu/localize"](
        "ingridients",
        this.product.ingridients
      );
    },

    validModificators() {
      return this.product.modificators.filter(
        (m) => m.name && m.price !== undefined && m.price !== null
      );
    },

    cart() {
      return this.$store.state.cart.items;
    },

    specialQuantity() {
      //BETYAR GRILL
      if (this.product.category != "BBQ & GRILL") return 1;
      if (this.product.name == "Шия барбекю") return 3;
      if (this.product.name == "Шашлик на мангалі") return 10;
      return 1;
    },

    currency() {
      return this.$store.state.currency.current;
    },

    isOnlyOnePrice() {
      return this.product.price_groups.length == 0;
      // return this.product.price_groups.length == 1 && this.product.price_groups[0].items.length == 1;
    },

    totalWeight() {
      if (!this.cartItem.variants || !this.cartItem.variants.length)
        return this.product.base_weight || 0;
      return this.cartItem.variants.reduce(
        (acc, item) => acc + (item.weight || 0),
        0
      );
    },

    totalWeightDisplayText() {
      const value = this.totalWeight;

      if (this.product.unit != "l" || value >= 1) {
        return value.toFixed(1) + " " + this.$t(`units.${this.product.unit}`);
      }

      const formatted = Math.round(value * 1000);

      return formatted.toFixed(1) + " " + this.$t(`units.ml`);
    },

    isMultipleVariants() {
      return (
        this.product.price_groups.filter((g) => g.single_select).length > 1
      );
    },

    promotionDiscount() {
      return this.$store.getters["promotions/getProductDiscount"](this.product);
    },

    quantityDisplayText() {
      if (this.product.weight_flag == 1) {
        const result_weight = this.totalWeight * this.cartItem.quantity;

        return `${result_weight.toFixed(2)} ${this.$t(
          "units." + this.product.unit
        )}`;
      } else {
        return `${this.cartItem.quantity}x`;
      }
    },

    isMenuClosed() {
      return !!this.menu.closed_until;
    },

    menuClosedWay() {
      if (!this.isMenuClosed) return 0;

      return moment().diff(this.menu.closed_until);
    },

    menuClosedTime() {
      if (!this.isMenuClosed) return "";

      return moment(this.menu.closed_until).format("HH:mm");
    },

    pricePerUnit() {
      if (`${this.target.cafe_id}` != "258") return null;
      if (this.product.weight_flag == 0) return null;
      if (
        this.product.price_groups.length &&
        this.product.price_groups.filter((pg) => pg.single_select).length > 0
      )
        return null;
      if (!this.product.base_weight) return null;

      let unit = ["g", "kg"].includes(this.product.unit)
        ? this.$t("units.kg")
        : this.$t("units.l");

      let price = this.product.base_price;
      let weight = this.product.base_weight;

      if (["ml", "g"].includes(this.product.unit)) {
        weight /= 1000;
      }

      //1 kg - x
      //base weight - price
      price = (1 * price) / weight;

      return {
        price: Math.round(price * 10) / 10,
        unit,
      };
    },

    productImage() {
      if (!this.product.image) {
        return "/icons/no-dish-exclusive.jpg";
      }

      if (this.product.image.includes("joinposter.com")) {
        // return this.product.image.replace("_original", "");
        return this.product.image
          .replace(".jpg", "_original.jpg")
          .replace(".png", "_original.png")
          .replace(".jpeg", "_original.jpeg");
      }

      return this.product.image;
    },
    hasEnoughProductInStock() {
      if (this.product.stock_count < 0) return true;
      if (this.cartItem.quantity >= 30) return false;

      return this.cartItem.quantity + 1 <= this.resultProductStock;
    },
    resultProductStock() {
      if (this.product.stock_count == -1) return 30;
      const item_count = this.$store.getters["cart/getItemCount"](
        this.product._id
      );
      return Math.max(0, this.product.stock_count - item_count);
    },
    shouldDescriptionBeShortened() {
      if (!this.product.description) return false;

      return this.product.description && this.fullDescriptionRows >= 6;
    },
    canAddToCart() {
      if (this.hideContent) return false;
      if (this.target.lite) return false;
      if (this.isMenuClosed) return false;
      if (this.disable_cart) return false;

      if (this.resultProductStock == 0) return false;

      return true;
    },

    fullDescriptionRows() {
      if (!this.product.description) return 0;

      return 0;
    },
  },
  mounted() {
    this.setCartItem();
  },
  components: {
    ProductList,
    SupportIcon,
    ModificatorsGroup,
    VariantsGroup,
    ARButton,
  },
};
</script>

<style scoped>
.pt-15 {
  padding-top: 1.8vh;
}

h4 {
  display: inline-block;
  font-size: 5.5vw;
  font-weight: 500;
  max-width: 60vw;
}

h4.dark {
  color: #ededed;
}

.img-block {
  position: fixed;
  border-radius: 0 0 5vh 5vh;
  top: 10vh;
  width: 100%;
  height: 50vh;
  margin-top: -3.2vh;
}

.img-regular {
  width: 89.8vw;
  height: 27vh;
  object-fit: cover;
  border-radius: 3vh 3vh 5vh 5vh;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 0;
  top: 0;
  margin: 0 auto;
  margin-top: 10vh !important;
  margin-bottom: 0;
}

.gradient-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0 0 5vh 5vh;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 39.58%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

img.arrow1 {
  margin-top: -0.5vh;
  width: auto;
  transition: 0.3s;
  height: 2vh;
}

img.arrow2 {
  margin-top: -0.5vh;
  width: auto;
  height: 2vh;
  transition: 0.3s;
  transform: rotate(90deg);
}

h5.modif {
  font-weight: 500;
  margin: 3.7vh 0 0 !important;
  padding: 0 !important;
  color: #a4a3a1;
}

h5.modif.dark {
  color: #a4a3a1;
}

.info.no-space {
  min-height: auto;
}

.variants {
  margin-top: 3.7vh;
}

.closed-bar {
  color: #1d1d1d;
  display: flex;
  width: 90%;
  margin: 7vw auto 0;
  justify-content: center;
  align-items: center;
}

.closed-bar.dark {
  color: #ededed;
}

.closed-bar img {
  width: auto;
  height: 7.5vw;
  margin-right: 2vw;
}

.closed-bar p {
  font-size: 4vw;
  line-height: normal;
  margin: 0;
  width: 75%;
}

.added2 {
  text-align: center;
  margin-bottom: 0;
  font-size: 5vw;
  font-weight: 500;
  padding-top: 4.7vh;
}

.added2.dark {
  color: #ededed;
}

li {
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

li span {
  right: 10vw;
  position: absolute;
  float: right;
}

span.dark {
  color: #ededed;
}

.no-shadow {
  box-shadow: none !important;
}

.modif-block {
  position: relative;
  padding-top: 0;
  margin-top: -0.6vh;
  animation: slide 0.3s;
}

@keyframes slide {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.modif img {
  margin-left: 5px;
}

h6 {
  margin: 10px 0;
}

.waiting-time {
  font-size: 2vh;
  line-height: normal;
  margin: 0;
  color: #908f8d;
}

img.icon {
  height: 1.8vh;
  width: auto;
  margin-right: 0.8vh;
}

.reccomendations {
  background: #ededed;
  position: relative;
  height: auto;
  margin-top: -7vh;
  z-index: 1;
  width: 100%;
  padding: 8vh 0 10vh;
}

.reccomendations.dark {
  background: #000000;
}

@keyframes popup {
  0% {
    top: 200px;
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.variants-block,
.modif-block {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.variant:first-child {
  margin: 0 !important;
}

.variant {
  padding: 1.3vh 4.7vw;
  margin: 1.9vh 0 0 0;
  color: #a4a3a1;
  font-size: 5vw;
  font-weight: 500;
  transition: 0.3s;
  background: transparent;
  box-shadow: 0px 1px 7px #dadada;
  border-radius: 10px;
  border: 1px solid #a4a3a1;
}

.variant.no-shadow {
  box-shadow: none !important;
}

.modif-title {
  margin: 0;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 5vw;
  padding-right: 6vh;
  max-height: 9vh;
  font-weight: 500;
}

.modif-item {
  max-width: 80vw;
  text-overflow: ellipsis;
  padding: 1.3vh 4.7vw 1.3vh 4.7vw;
  margin: 1.9vh 0 0;
  color: #1d1d1b;
  display: flex;
  transition: 0.2s;
  border: 2px solid #ededed;

  background: #ededed;
  box-shadow: 0px 1px 7px #dadada;
  border-radius: 10px;
}

.modif-black {
  margin: 1.9vh 0 0;
  color: #a4a3a1;
  border: 2px solid #a4a3a1;
  max-width: 90vw !important;
  font-size: 5vw;
  font-weight: 500;
  transition: 0.2s;
  background: transparent;
  box-shadow: none !important;
  border-radius: 10px;
}

.modif-black-active.dark {
  background: transparent;
  border: 2px solid #129b81;
  color: #ededed !important;
}

.modif-item:last-child {
  margin-bottom: 0 !important;
}

.modif-item span {
  position: relative;
  right: 0;
  align-self: center;
  font-size: 2.3vh;
}

.modif-active {
  border: 2px solid #1d1d1b;
}

.modif-black-active {
  max-width: 90vw !important;
  color: #1d1d1b !important;
  box-shadow: none !important;
  border: 2px solid #129b81;
}

.variant-active {
  background: #129b81;
  color: #ededed;
}

/* .variant-black {
  color: #1d1d1b;
} */

.variant-black-active {
  background-color: #1d1d1b;
  color: #ededed;
  border: 1px solid transparent;
}

.variant-black-active.dark {
  background: #ededed;
  color: #1d1d1b;
}

ul {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

.quantity {
  display: flex;
  justify-content: space-around;
  margin: 3.7vh 0;
  font-weight: 500;
  color: black;
  font-size: 24px;
}

.quantity img {
  height: 4.2vh;
  width: auto;
}

.addit-price {
  float: right;
  font-size: 18px;
  color: #bcbcbc;
  margin: -5px 0;
}

p.ingridients {
  padding-top: 1vh;
  color: #464646;
  font-size: 4.5vw;
  margin-bottom: 0;
  font-weight: 400;
}

p.ingridients.dark {
  color: #a4a3a1;
}

p.weight {
  color: #908f8d;
  font-size: 2vh;
  line-height: normal;
  padding-right: 2.3vh;
  margin-bottom: 0;
  font-weight: 400;
}

p img {
  width: 6vw;
  height: auto;
  margin: -0.5vh 2vw 0 0;
}

button span {
  float: right;
  font-size: 2.3vh;
}

.lux-add {
  background: #129b81 !important;
}

.add-to-cart {
  background: #129b81;
  padding: 1.6vh;
  width: 100%;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5vh;
  display: block;
  font-size: 2.3vh;
  font-weight: 500;
  margin: 0 auto;
  border: none;
  color: white;
}

span.price {
  float: right;
  font-size: 8vw;
  margin-top: -0.6vh;
  line-height: 0.8;
  font-weight: 500;
  color: black;
}

span.price.dark {
  color: #ededed;
}

.lux-price {
  color: #1d1d1b !important;
}

.details-black {
  color: white !important;
  background: #1d1d1b !important;
}

.details-black > * {
  color: white !important;
}

.product-details {
  animation: slide-top 0.5s;
  background: #ededed;
  border-radius: 3vh 3vh 5vh 5vh;
  z-index: 2;
  width: 90vw;
  padding: 3.7vh 5.4vw;
  margin: 0 auto;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.lux-details {
  padding: 3.7vh 5.4vw;
  overflow-x: hidden;
  width: 100% !important;
  background: #ededed;
  z-index: 2;
}

.dark {
  background: #1d1d1b;
}

.misc-params {
  margin-top: 3vh;
  display: flex;
  align-items: center;
}

@keyframes slide-top {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

.img-exclusive {
  object-fit: cover;
  border-radius: 0 0 5vh 5vh;
  width: 100%;
  height: 100%;
}

img.img-description {
  object-fit: cover;
  border-radius: 3vh 3vh 5vh 5vh;
  width: 100%;
  height: 100%;
}
.info {
  overflow: auto !important;
  background: transparent;
  position: relative;
  z-index: 4;
  margin-top: 48vh;
  padding: 0 0 1vh 0;
}

h2 {
  padding: 0;
  overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
  font-size: 10.7vw;
  font-weight: 600;
  display: -webkit-box;
  line-height: 6vh;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 90vw;
  margin: 0 auto;
}

.lux-title p {
  font-size: 5vw;
  margin-bottom: 0;
  width: 90vw;
  margin: 1vh auto 0;
  font-weight: 600;
}

.lux-title {
  font-weight: 500;
  z-index: 0;
  font-size: 10.7vw;
  width: 100%;
  margin-top: 20vh;
  height: 27vh;
  text-align: center;
  position: absolute;
}

.weight-text {
  display: inline-block;
}

.price-group {
  margin-bottom: 3.7vh;
}

.price-groups {
  margin-top: 3vh;
}

.price-discount {
  color: #129b81 !important;
}

.menu-closed-block {
  text-align: center;
  margin-top: 3.7vh;

  color: #a4a3a1;
}

.show-more {
  color: #908f8d;
}

.show-more.dark {
  color: #ededed;
}

.ar-icon {
  position: absolute;

  right: 2vh;

  bottom: 10.7vh;
}
</style>
