<template>
  <div>
    <div class="pop-up-bg"></div>
    <transition name="slide">
      <div
        v-show="show"
        id="dish-pop-up-desktop"
        :class="{ 'dish-pop-up-desktop': true, 'pb-0': is_scroll_in_bottom }"
      >
        <div class="dish-content-wrapper" id="dishContentWrapper">
          <img
            src="/icons/desktop/dish-scroll-icon.svg"
            @click="scrollContent()"
            :class="{ 'scroll-icon': true, 'reverse-arrow': scroll_top }"
          />
          <img
            src="/icons/desktop/dish-close-icon.svg"
            @click="$emit('close')"
            class="close-icon"
          />
          <div class="first-row">
            <div class="image-container">
              <img class="product-image" :src="productImage" alt="" />
            </div>
            <div class="dish-info-block">
              <div class="row-space-between">
                <div class="product-title">{{ product.name }}</div>
                <div class="product-price">
                  {{ uiTotalPrice }}{{ currency.symbol }}
                </div>
              </div>
              <div class="product-description">{{ product.description }}</div>
              <div class="product-characteristics">
                <div
                  class="product-characteristic"
                  v-if="totalWeightDisplayText"
                >
                  <img src="/icons/desktop/product-weight.svg" alt="" />
                  {{ totalWeightDisplayText }}
                </div>
                <div class="product-characteristic" v-if="pricePerUnit">
                  {{
                    $t("menu.price_per_unit", {
                      unit: pricePerUnit.unit,
                    })
                  }}
                  {{ pricePerUnit.price }} {{ currency.symbol }}
                </div>
                <!-- <div class="product-characteristic">
                  <img src="/icons/desktop/waiting-time.svg" alt="" />30 min
                </div> -->
              </div>
            </div>
          </div>
          <div id="scroll-able-content">
            <!-- <div v-if="false" class="label">Variants</div>
            <div v-if="false">
              <div class="additions-container">
                <div
                  v-for="price in product.prices"
                  :key="price._id"
                  class="radio-selector-item"
                >
                  {{ price.variant }} <span>{{ price.value }}₴</span>
                </div>
                <div class="radio-selector-item">Small <span>9999₴</span></div>
                <div class="radio-selector-item">Small <span>9999₴</span></div>
                <div class="radio-selector-item">Small <span>9999₴</span></div>
                <div class="radio-selector-item">Small <span>9999₴</span></div>
                <div class="radio-selector-item">Small <span>9999₴</span></div>
              </div>
            </div>
            <div v-if="false" class="label">+ Additions</div>
            <div v-if="false" class="additions-container">
              <div
                v-for="(item, index) in product.modificators"
                :key="item._id"
                :class="{
                  'radio-selector-item': true,
                  'active-radio-item': isSelectedModificator(index),
                }"
                @click="SelectModificator(index)"
              >
                {{ item.name }} <span>{{ item.price }}₴</span>
              </div>
            </div> -->

            <!--  NEW GROUPS -->
            <template v-if="!added_to_cart">
              <div
                class="price-group"
                v-for="(price_group, index) in product.price_groups || []"
                :key="index"
              >
                <div v-if="price_group.single_select">
                  <VariantsGroup
                    :group="price_group"
                    :dish="product"
                    @add="(v) => cartItem.variants.push(v)"
                    @remove="(i) => cartItem.variants.splice(i, 1)"
                    :selected="cartItem.variants"
                    :show_prices="true"
                  />
                </div>
                <div v-else>
                  <ModificatorsGroup
                    :group="price_group"
                    :selected="cartItem.modificators"
                    @toggle="onModificatorToggle"
                    v-if="price_group.items.length"
                  />
                </div>
              </div>
            </template>
          </div>

          <div
            :class="
              is_scroll_in_bottom || !product.price_groups.length
                ? 'default-position'
                : 'fixed-container'
            "
          >
            <div class="second-row" v-show="!disableCart">
              <div class="second-row-section" :style="{ visibility: 'hidden' }">
                <button class="add-to-favorites">
                  <img src="/icons/black-like-outlined.svg" alt="" />Add to
                  favourites
                </button>
                <img
                  v-if="false"
                  class="added-to-favourites"
                  src="/icons/desktop/added-to-favourites-icon.svg"
                />
              </div>

              <div class="flex-justify-content-center second-row-section">
                <img
                  class="count-controll-btn"
                  src="/icons/desktop/count-decrement-icon.svg"
                  @click="changeQuantity(-1)"
                  :style="{
                    visibility:
                      cartItem.quantity > 1 && !added_to_cart
                        ? 'visible'
                        : 'hidden',
                  }"
                />
                <div
                  :style="{
                    visibility: !added_to_cart ? 'visible' : 'hidden',
                  }"
                  class="count-control-counter"
                >
                  {{ cartItem.quantity }}x
                </div>
                <img
                  class="count-controll-btn"
                  src="/icons/desktop/count-increment-icon.svg"
                  @click="changeQuantity(1)"
                  :style="{
                    visibility:
                      cartItem.quantity < maxPossibleQuantity && !added_to_cart
                        ? 'visible'
                        : 'hidden',
                  }"
                />
              </div>
              <div class="second-row-section">
                <button
                  v-if="!added_to_cart"
                  class="add-to-cart-btn"
                  @click="addToCart()"
                >
                  {{ $t("menu.add_to_cart") }}
                </button>
                <div v-else class="added-to-cart-row">
                  <img src="/icons/desktop/green-fill-ok-icon.svg" alt="" />{{
                    $t("menu.added_to_cart")
                  }}
                  (х{{ cartItem.quantity }})
                </div>
              </div>
            </div>
            <div class="third-row">
              <div class="recomendet-dishes-title">
                {{ $t("menu.recommending") }}
              </div>
              <div class="recomendet-dishes-container">
                <div
                  v-for="rec in recommendations"
                  :key="rec._id"
                  class="recomendet-dish"
                  @click="
                    $emit('selectRecommendation', rec),
                      (is_scroll_in_bottom = false)
                  "
                >
                  <img
                    :src="rec.image || '/icons/no-dish-exclusive.jpg'"
                    class="recomendet-dish-image"
                  />
                  <div class="recomendet-dish-name">
                    {{ rec.name }}
                  </div>
                  <div class="recomendet-dish-price">
                    {{ getRecommendationPrice(rec) }}{{ currency.symbol }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></transition
    >
  </div>
</template>
<script>
import ModificatorsGroup from "@/components/menu/ModificatorsGroup.vue";
import VariantsGroup from "@/components/menu/VariantsGroup.vue";

export default {
  props: {
    menu: Object,
    product: Object,
    recommendations: Array,
    disableCart: Boolean,
  },
  components: {
    ModificatorsGroup,
    VariantsGroup,
  },
  data() {
    return {
      scroll_top: null,
      is_scroll_in_bottom: false,
      slide_arrow: false,
      selected_modificatirs: [],
      product_count: 1,
      show_scroll_arrow: true,
      show_content: false,
      show: false,
      is_position_absolute: true,

      added_to_cart: false,

      cartItem: {
        name: null,
        category: null,
        price: null,
        variant: null,
        waiting_time: null,
        modificators: null,
        quantity: 1,
        weight: null,
        unit: null,
        weighted: null,
        is_excise: false,
        menu_id: null,
        product_id: null,
        variants: [],
        max_count: 10,
        available: true,
      },
    };
  },
  watch: {
    scroll_top() {
      let scrollableContent = document.getElementById("scroll-able-content");
      if (this.scroll_top >= scrollableContent.offsetHeight) {
        this.is_scroll_in_bottom = true;
        this.slide_arrow = true;
      } else {
        this.is_scroll_in_bottom = false;
        this.slide_arrow = false;
      }
    },
    product() {
      this.updateCartItem();
    },
  },
  methods: {
    changeQuantity(delta) {
      const new_quantity = this.cartItem.quantity + delta;

      if (new_quantity <= 0 || new_quantity > this.maxPossibleQuantity) return;

      this.cartItem.quantity += delta;
    },
    isSelectedModificator(index) {
      return this.selected_modificatirs.includes(index);
    },
    scrollContent() {
      let scrollableContent = document.getElementById("scroll-able-content");
      let popUp = document.getElementById("dish-pop-up-desktop");
      if (this.scroll_top) {
        popUp.scrollTop = 0;
      } else popUp.scrollTop = scrollableContent.offsetHeight;
    },
    SelectModificator(index) {
      if (this.selected_modificatirs.includes(index)) {
        let modificatorIndex = this.selected_modificatirs.indexOf(index);
        console.log(modificatorIndex);
        this.selected_modificatirs.splice(modificatorIndex, 1);
        console.log(this.selected_modificatirs);
      } else {
        this.selected_modificatirs.push(index);
      }
    },
    onModificatorToggle(m) {
      const cartModIndex = this.cartItem.modificators.findIndex(
        (i) => i.name === m.name
      );

      if (cartModIndex === -1) {
        this.cartItem.modificators.push(m);
      } else {
        this.cartItem.modificators.splice(cartModIndex, 1);
      }
    },
    addToCart() {
      this.cartItem.variant = this.cartItem.variants
        .map((v) => {
          if (v.name) return v.name;

          if (v.weight) {
            return `${v.weight} ${this.$t("units." + this.product.unit)}`;
          }

          return null;
        })
        .join(", ");
      this.cartItem.price = this.totalPrice || 0;
      this.cartItem.weight = this.totalWeight;
      this.cartItem.initial_price = this.variantsPrice;
      this.cartItem.total_weight =
        this.product.weight_flag != 0
          ? this.cartItem.quantity * this.cartItem.weight
          : this.totalWeight;

      this.$store.dispatch("cart/addItem", Object.assign({}, this.cartItem));

      this.$store.dispatch("promotions/checkCart");

      this.$emit("addedToCart", this.product);

      this.added_to_cart = true;
    },
    updateCartItem() {
      this.cartItem.name = this.product.name;
      this.cartItem.category = this.product.category;
      this.cartItem.price = this.product.base_price || 0;
      this.cartItem.variant = "";
      this.cartItem.waiting_time = this.product.waiting_time || 0;
      this.cartItem.modificators = [];
      this.cartItem.quantity = 1;
      this.cartItem.weight = this.product.base_weight || 0;
      this.cartItem.unit = this.product.unit || "g";
      this.cartItem.weighted = this.product.weighted || false;
      this.cartItem.is_excise = this.product.is_excise || false;
      this.cartItem.weight_flag = this.product.weight_flag || 0;
      this.cartItem.menu_id = this.menu._id;
      this.cartItem.product_id = this.product._id;
      this.cartItem.max_count =
        this.product.stock_count > 0 ? this.product.stock_count : 30;
      this.cartItem.variants = [];

      this.product.price_groups.forEach((group) => {
        if (!group.single_select) return;

        if (!group.show_for || !group.show_for.length) {
          this.cartItem.variants.push(group.items[0]);
        } else {
          const possible_variant = this.cartItem.variants.find(
            (v) => group.show_for.includes(v._id)
          );

          if (possible_variant) {
            this.cartItem.variants.push(group.items[0]);
          }
        }
      });

      this.added_to_cart = false;
    },
    onBackgroundClicked(ev) {
      const user_y = ev.pageY;

      const el_y = document
        .getElementById("dishContentWrapper")
        .getBoundingClientRect().top;

      console.log(`${user_y} <= ${el_y}`);

      if (user_y < el_y - 60) {
        this.$emit("close");
      }
    },
    getRecommendationPrice(p) {
      const base_price = p.base_price || 0;

      const variants_group = p.price_groups.find((g) => g.single_select);

      if (
        variants_group &&
        variants_group.items[0] &&
        variants_group.items[0].price
      ) {
        return variants_group.items[0].price + base_price;
      }

      return base_price;
    },
  },
  created() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("no-scroll");
    setTimeout(() => {
      this.show = true;
      let popUp = document.getElementById("dish-pop-up-desktop");
      popUp.addEventListener("scroll", (e) => {
        this.scroll_top = popUp.scrollTop;
      });
    }, 100);
  },
  mounted() {
    this.updateCartItem();
    window.addEventListener("click", this.onBackgroundClicked);
  },
  destroyed() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("no-scroll");
    window.removeEventListener("click", this.onBackgroundClicked);
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    maxPossibleQuantity() {
      const pr_s =
        this.product.stock_count == -1 ? 30 : this.product.stock_count;
      return pr_s;
    },
    variantsPrice() {
      let value =
        (this.product.base_price || 0) +
        this.cartItem.variants.reduce((acc, item) => acc + item.price, 0);

      return value;
    },
    totalPrice() {
      let value = this.variantsPrice;

      // if (this.promotionDiscount)
      //   value = Math.round(this.promotionDiscount * value);

      return value;
    },
    uiTotalPrice() {
      let price = this.totalPrice;

      if (this.cartItem.modificators)
        this.cartItem.modificators.forEach((m) => {
          price += m.price;
        });
      if (this.cartItem.quantity > 1) {
        price *= this.cartItem.quantity;
      }

      return price;
    },
    totalWeight() {
      if (!this.cartItem.variants || !this.cartItem.variants.length)
        return this.product.base_weight || 0;
      return this.cartItem.variants.reduce(
        (acc, item) => acc + (item.weight || 0),
        0
      );
    },

    pricePerUnit() {
      if (this.product.weight_flag == 0) return null;
      if (
        this.product.price_groups.length &&
        this.product.price_groups.filter((pg) => pg.single_select).length > 0
      )
        return null;
      if (!this.product.base_weight) return null;

      let unit = ["g", "kg"].includes(this.product.unit)
        ? this.$t("units.kg")
        : this.$t("units.l");

      let price = this.product.base_price;
      let weight = this.product.base_weight;

      if (["ml", "g"].includes(this.product.unit)) {
        weight /= 1000;
      }

      //1 kg - x
      //base weight - price
      price = (1 * price) / weight;

      return {
        price: Math.round(price * 10) / 10,
        unit,
      };
    },

    totalWeightDisplayText() {
      const value = this.totalWeight;

      if (!this.totalWeight) return null;

      if (this.product.unit != "l" || value >= 1) {
        return value + " " + this.$t(`units.${this.product.unit}`);
      }

      const formatted = Math.round(value * 1000);

      return formatted + " " + this.$t(`units.ml`);
    },
    productImage() {
      if (!this.product.image) {
        return "/icons/no-dish-exclusive.jpg";
      }

      if (this.product.image.includes("joinposter.com")) {
        // return this.product.image.replace("_original", "");
        return this.product.image
          .replace(".jpg", "_original.jpg")
          .replace(".png", "_original.png")
          .replace(".jpeg", "_original.jpeg");
      }

      return this.product.image;
    },
  },
};
</script>
<style scoped>
.pop-up-bg {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}
.dish-pop-up-desktop {
  position: fixed;
  z-index: 21;
  top: 6.222222222222222vh;
  left: 0;
  width: 100%;
  height: 93.88888888888vh;
  padding-top: 48.4vh;
  padding-bottom: 12.6vh;
  overflow: auto;
  scroll-behavior: smooth;
  user-select: none;
}
.dish-pop-up-desktop::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.dish-content-wrapper {
  margin-top: 8.555555555555555vh;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}
.first-row {
  display: flex;
  padding: 3.3333333333333335vh 11.11111111111111vh;
  background: #ededed;
}

.image-container {
  width: 83%;
  height: 18.8vw;
  margin-top: -10.88888888888889vh;
  margin-right: 5.555555555555555vh;
  z-index: 1;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 2.2222222222222223vh;
  /* filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.25)); */
}
.dish-info-block {
  width: 100%;
}
.row-space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8888888888888888vh;
}
.product-title {
  font-weight: bold;
  font-size: 2.6666666666666665vh;
  line-height: 3.111111111111111vh;
  color: #1d1d1b;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-price {
  font-weight: normal;
  font-size: 3.3333333333333335vh;
  line-height: 3.888888888888889vh;
  color: #1d1d1b;
}
.product-description {
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #464646;
  margin-top: 1.1111vh;
}
.product-characteristics {
  display: flex;
  margin-top: 2.222vh;
}
.product-characteristic {
  font-weight: normal;
  font-size: 1.6666666666666667vh;
  line-height: 130.8%;
  color: #908f8d;
  display: flex;
  align-items: center;
  margin-right: 2.222vh;
}
.product-characteristic:last-child {
  margin-right: 0;
}
.product-characteristic img {
  width: 1.6666666666666667vh;
  height: 1.6666666666666667vh;
  margin-right: 0.5555555555555556vh;
}
.second-row {
  width: 100vw;
  padding: 0 11.1111vh;
  height: 12.222222222222221vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ededed;
  backdrop-filter: blur(20px);
}
.second-row-section {
  width: 32.26%;
}
.flex-justify-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-to-favorites {
  padding: 1.2222222222222223vh 2.222vh;
  background: rgba(237, 237, 237, 0.1);
  border: 0.111vh solid #1d1d1b;
  box-sizing: border-box;
  border-radius: 4vh;
  display: flex;
  align-items: center;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #1d1d1b;
}
.add-to-favorites img {
  width: 2.6666666666666665vh;
  height: 2.6666666666666665vh;
  margin-right: 1.1111vh;
}
.count-controll-btn {
  width: 4.444444444444445vh;
  height: 4.444444444444445vh;
  cursor: pointer;
}
.count-control-counter {
  font-weight: normal;
  font-size: 2.6666666666666665vh;
  line-height: 3.111111111111111vh;
  color: #1d1d1b;
  width: 7.777777777777778vh;
  text-align: center;
  margin: 0 2.222vh;
}
.add-to-cart-btn {
  width: 100%;
  padding: 1.7777777777777777vh 0;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
  background: #129b81;
  border-radius: 2.7777777777777777vh;
  border: none;
}
.added-to-cart-row {
  width: 100%;
  padding: 1.7777777777777777vh 0;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #1d1d1b;
  border-radius: 2.7777777777777777vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.added-to-cart-row img {
  width: 2.4444444444444446vh;
  height: 2.4444444444444446vh;
  margin-right: 1.1111111111111112vh;
}
.scroll-icon {
  position: absolute;
  width: 5.555555555555555vh;
  height: 5.555555555555555vh;
  left: calc(50% - 2.6vh);
  top: -6.5vh;
  cursor: pointer;
  transition: transform 0.5s;
}
.close-icon {
  width: 5.555555555555555vh;
  height: 5.555555555555555vh;
  position: absolute;
  right: 2.222vh;
  top: -6.5vh;
  cursor: pointer;
}
.third-row {
  background: rgba(237, 237, 237, 0.8);
  backdrop-filter: blur(20px);
  width: 100%;
  padding: 3.3333333333333335vh 11.1111vh 5.555555555555555vh;
}
.recomendet-dishes-title {
  font-size: 2.7777777777777777vh;
  line-height: 3.2222222222222223vh;
  color: #1d1d1b;
  margin-bottom: 1.6666666666666667vh;
  cursor: pointer;
}
.recomendet-dishes-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.recomendet-dish {
  display: flex;
  width: 31.69%;
  justify-content: space-between;
  cursor: pointer;
}
.recomendet-dish-image {
  width: 43.26%;
  height: 9.444444444444445vh;
  object-fit: cover;
  object-position: center;
  border-radius: 1.1111111111111112vh;
}
.recomendet-dish-name {
  font-size: 2vh;
  line-height: 115%;
  color: #1d1d1b;
  width: calc(49.11% - 8.777777777777779vh);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.recomendet-dish-price {
  font-size: 2.5555555555555554vh;
  line-height: 2.888888888888889vh;
  color: #464646;
  width: 8.777777777777779vh;
}
.fixed-container {
  position: fixed;
  bottom: -23.3vh;
  left: 0;
}
.added-to-favourites {
  width: 5.555555555555555vh;
}
.default-position {
  position: static;
}
.pb-0 {
  padding-bottom: 0 !important ;
}
.label {
  font-size: 2vh;
  line-height: 2.3333333333333335vh;
  color: #908f8d;
  margin-bottom: 1.6666666666666667vh;
}
#scroll-able-content {
  width: 100%;
  background: #ededed;
  padding: 0 11.1111vh 0;
  scroll-behavior: smooth;
}
.radio-selector-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6666666666666667vh;
}
.radio-selector-row:last-child {
  margin-bottom: 3.3333333333333335vh;
}
.radio-selector-item {
  width: 32.74%;
  padding: 1.4444444444444444vh 1.6666666666666667vh;
  border: 0.1111vh solid #908f8d;
  box-sizing: border-box;
  border-radius: 1.1111111111111112vh;
  font-size: 2.3333333333333335vh;
  line-height: 2.6666666666666665vh;
  color: #908f8d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.radio-selector-item span {
  width: 6.777777777777778vh;
  text-align: right;
}
.active-radio-item {
  background: #1d1d1b;
  border: 0.1111vh solid #1d1d1b;
  color: #ededed;
}
.additions-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.additions-container .radio-selector-item {
  margin-right: 0.81%;
  margin-bottom: 1.6666666666666667vh;
}
.additions-container .radio-selector-item:nth-child(3n) {
  margin-right: 0;
}
.reverse-arrow {
  transform: rotateX(180deg);
}
.bottom-border {
  margin-top: 1.6666666666666667vh;
  width: 100%;
  height: 0.1111vh;
  background: #908f8d;
  border-radius: 0.3333333333333333vh;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
</style>
