<template>
  <div class="system-pop-up-desktop">
    <div class="pop-up-content-wrapper">
      <div :class="`pop-up-content-container ${bg_color}`">
        <div class="pop-up-content-header">
          <div :class="`system-name ${text_color}`">{{ 
            mode == 'delivery' ? 'DELIVERY' : 'TAKEAWAY' 
          }}</div>
          <img class="panda-image" :src="panda" alt="" v-if="!cafe.ui_settings.hide_panda" />
        </div>
        <div class="pop-up-data-container">
          <div class="big-text mb-5vh">
            {{ $t(`mode_modal.${mode}_title`) }}
          </div>
          <!-- <div class="big-text mb-5vh" v-if="deliveryState">
            {{ $t("mode_modal.delivery_price_header") }}
          </div>
          <div class="small-text mb-30vh" v-if="deliveryState">
            {{
              $t(`mode_modal.delivery_types.${deliveryState.type}`, {
                cost: deliveryState.cost,
                currency: currency.symbol,
              })
            }}
          </div>
          <div
            class="small-text mb-30vh"
            v-if="deliveryState && deliveryState.min_check"
          >
            {{
              $t(`mode_modal.delivery_types.conditional`, {
                min_check: deliveryState.min_check,
                currency: currency.symbol,
              })
            }}
          </div>
          <div class="big-text mb-5vh" v-if="hasMinCheck">
            {{ $t("mode_modal.min_check_header") }}
          </div>
          <div class="small-text mb-30vh" v-if="hasMinCheck">
            {{ $t("mode_modal.min_check_content") }} {{ minCheckCost || 0
            }}{{ currency.symbol }}
          </div> -->
          <div class="big-text" v-html="processedMotd" v-if="motd"></div>
        </div>
      </div>
      <div class="action-btn-container">
        <!-- <div class="action-pop-up-btn" @click="dontShowAgain">
          {{ $t("mode_modal.btn_hide") }}
        </div> -->
        <div class="action-pop-up-btn" @click="emitClose">
          {{ $t("mode_modal.btn_menu") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mode: {
      type: String,
      default: "delivery",
    },
    cafe: Object,
    motd: String,
    minCheckCost: {
      type: Number,
      default: null,
    },
    deliveryState: Object,
  },
  data() {
    return {
      bg_color: `bg-${this.mode}`,
      text_color: `text-${this.mode}`,
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    dontShowAgain() {
      window.localStorage.setItem(
        `order_modal_hidden:${this.cafe._id}`,
        `${Date.now()}`
      );

      this.emitClose();
    },
  },
  mounted() {
    // const was_hidden = window.localStorage.getItem(`order_modal_hidden:${this.cafe._id}`);

    // if (was_hidden) this.emitClose();
  },
  computed: {
    panda() {
      return `/icons/desktop/panda-${this.mode}.png`;
    },
    hasMinCheck() {
      return this.minCheckCost !== null;
    },
    currency() {
      return this.$store.state.currency.current;
    },
    processedMotd() {
      if (!this.motd) return '';
      return this.motd.split('\n').join('<br>');
    }
  },
};
</script>
<style scoped>
.system-pop-up-desktop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-up-content-container {
  background: #cbd9e6;
  border-radius: 2.2222vh;
  width: 86.66666666666667vh;
  min-height: 69.11111111111111vh;
  overflow: hidden;
}
.pop-up-content-header {
  width: 100%;
  height: 18.333333333333332vh;
  background: #1d1d1b;
  padding-top: 3.3333333333333335vh;
  padding-left: 4.777777777777778vh;
  position: relative;
}
.system-name {
  font-size: 3.111111111111111vh;
  line-height: 85.69%;
  text-transform: uppercase;
}
.panda-image {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 16.333333333333332vh;
}
.pop-up-data-container {
  padding: 3.33333vh 2.2222vh;
}
.big-text {
  font-size: 2.2222vh;
  line-height: 100%;
  color: #1d1d1b;
}
.small-text {
  font-size: 1.6666666666666667vh;
  line-height: 1.8888888888888888vh;
  color: #464646;
}
.mb-5vh {
  margin-bottom: 0.5555555555555556vh;
}
.mb-30vh {
  margin-bottom: 3.3333333333333335vh;
}
.action-btn-container {
  width: 86.66666666666667vh;
  display: flex;
  margin-top: 2.222vh;
  justify-content: space-between;
}
.action-pop-up-btn {
  width: 100%;
  height: 5.555555555555555vh;
  background: #464646;
  border-radius: 1.1111vh;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.action-pop-up-btn:last-child {
  background: #1d1d1b;
}
.text-delivery {
  color: #cbd9e6;
}
.text-preorder {
  color: #ffd2a8;
}
.text-reserve {
  color: #aeccc6;
}
.text-rooms {
  color: #ededed;
}
.bg-delivery {
  background: #cbd9e6;
}
.bg-preorder {
  background: #ffd2a8;
}
.bg-reserve {
  background: #aeccc6;
}
.bg-rooms {
  background: #ededed;
}
</style>
