var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.showServices && !_vm.specialServiceButton && _vm.menus.length == 1 && _vm.showServices
  )?_c('div',{staticClass:"services-space"},[_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"services-btn",on:{"click":function($event){return _vm.$emit('servicesClicked')}}},[_c('img',{attrs:{"src":_vm.isDarkMode
          ? '/icons/services-icon-only-white.svg'
          : '/icons/services-icon-only.svg'}}),_vm._v("\n    "+_vm._s(_vm.$t('navbar.services'))+"\n  ")])]):_c('div',{staticClass:"menu-tabs"},[_c('div',{staticClass:"space"},[(_vm.showServices && _vm.specialServiceButton)?_c('div',{staticClass:"red-call-service-button",class:{
        disabled: _vm.call_waiter_timeout > 0,
      },on:{"click":function($event){return _vm.$emit('specialServiceButtonClicked')}}},[_c('img',{attrs:{"src":"/icons/bell-bold.svg","alt":""}}),_vm._v("\n      Call waiter\n      "),(_vm.call_waiter_timeout)?[_vm._v("("+_vm._s(_vm.call_waiter_timeout)+" s.)")]:_vm._e()],2):(_vm.showServices && !_vm.specialServiceButton)?_c('img',{staticClass:"services-icon",attrs:{"src":_vm.isDarkMode
          ? '/icons/services-icon-white.svg'
          : '/icons/services-icon.svg'},on:{"click":function($event){return _vm.$emit('servicesClicked')}}}):_vm._e(),(_vm.menus.length > 1)?_vm._l((_vm.menus),function(m,index){return _c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],key:m._id,class:[_vm.getTabClass(index), _vm.menus.length <= 2 ? 'wide' : ''],on:{"click":function($event){return _vm.doTheSwitch(index)}}},[_vm._v("\n        "+_vm._s(_vm.getDisplayTitle(m) || ("" + (index + 1)))+"\n      ")])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }