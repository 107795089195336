<template>
  <div>
    <div :class="dynamicClass">
      <img
        :src="icon"
        alt="Status Icon"
        v-if="!newDesign"
        :class="cancelled ? 'cancelled' : ''"
      />

      <div
        :class="
          newDesign ? 'd-flex w-100 justify-content-between' : 'pr-1 w-100'
        "
      >
        <!-- <h4 class="color-white" v-if="!newDesign">{{ header }}</h4> -->
        <div v-if="!newDesign">
          <h4 class="color-white">{{ header }}</h4>
        </div>
        <h4 class="color-white special" v-if="newDesign">{{ headerMode }}</h4>
        <h4 class="special float-right" v-if="newDesign && orderId">
          <small>id:</small>
          {{ orderId }}
        </h4>
        <span v-if="!newDesign" class="color-white">{{ statusString }}</span>

        <!-- <span
        v-if="waitingTime && order.status === 'serving'"
        >{{$t('card.waiting_time')}} {{ waitingTimeString }}</span>-->
      </div>
    </div>
    <h4 v-if="newDesign && !cartMode" class="paid-status">
      {{ statusString }}
    </h4>
  </div>
</template>

<style scoped>
.order-card-header {
  animation: change-color 0.5s;
  position: relative;
  top: -1px;
  width: 100.2%;
  display: flex;
  background-color: #303030;
  color: white;
  border-radius: 2.3vh 2.3vh 0 0;
  padding: 2.7vh 2.5vh 2.5vh;
}

@keyframes change-color {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

img {
  width: auto;
  height: 5vh;
  margin: 0 2.5vh 0 0;
}

.img-pay {
  margin-top: 0.5vh;
  width: 8vw;
  height: 5.5vh;
}

span.color-white {
  font-size: 2.2vh;
  line-height: 1;
  display: block;
  margin-top: 0.8vh;
  position: relative;
  font-weight: 300;
}

h4 {
  margin-bottom: 0;
  line-height: 0.8;
  font-size: 3vh;
}

h4.paid-status {
  background: #ededed;
  color: #1d1d1d;
  font-size: 3.6vh;
  padding: 1.3vh 2.5vh;
}

h4.special {
  display: inline-block;
  line-height: 0.8;
  font-size: 3.7vh;
}

small {
  font-size: 2.3vh;
}

.cancelled {
  width: auto !important;
}

.status-waiting {
  background-color: #1d1d1b;
}

.status-paying {
  background: #1d1d1b;
}

.status-serving {
  background: #416293;
}

.status-cancelled {
  background-color: #e2223b;
}

.status-unpaid {
  background: #1d1d1b;
}

.status-executed {
  background-color: #129b81;
  padding-bottom: 1.2vh;
}
@media screen and (min-width: 1024px) {
  .order-card-header {
    border-radius: 1.6vh 1.6vh 0 0;
    padding: 2.7777777777777777vh 2.2222vh;
  }
  h4.special {
    font-size: 3.3333vh;
    line-height: 70%;
  }
}
</style>

<script>
import moment from "moment";

export default {
  props: {
    order: Object,
    target: Object,
    number: Number,
    cartMode: {
      type: Boolean,
      default: false,
    },
    isAppend: {
      type: Boolean,
      default: false,
    },
    waitingTime: {
      type: Number,
      default: 0,
    },
    is_delivery: Boolean,
  },
  data() {
    return {
      acceptedMinutes: 0,
      timerId: 0,
      cancelled: false,
    };
  },
  methods: {
    tickAcceptMinutes() {
      if (!this.order || this.cartMode) return;

      this.acceptedMinutes = Math.abs(
        moment().diff(
          moment(this.order.processed_at || this.order.date),
          "minutes"
        )
      );
    },
  },
  computed: {
    status() {
      if (this.cartMode) {
        return this.$t("card.status_cart");
      }

      const raw = this.order.status;

      if (raw === "waiting") {
        return this.$t("card.status_waiting");
      }

      if (raw === "serving") {
        if (this.order.served_at) {
          return this.$t("card.status_served");
        }

        if (this.acceptedMinutes < 1) {
          return this.$t("card.status_serving_recent");
        } else {
          return this.$t("card.status_serving", {
            mins: this.acceptedMinutes,
          });
        }
      }

      if (raw === "cancelled") {
        this.cancelled = true;
        return this.$t("card.status_cancelled");
      }

      if (raw === "unpaid") {
        return this.$t("card.status_unpaid");
      }

      if (raw === "paying") {
        return this.$t("card.status_paying");
      }

      if (raw === "executed") {
        return this.$t("card.status_executed");
      }

      return "???";
    },
    icon() {
      if (this.cartMode) {
        return "/icons/edit-list.png";
      }

      if (this.order.status === "waiting" || this.order.status === "serving") {
        return "/icons/accepted.png";
      }

      if (["unpaid", "paying"].includes(this.order.status)) {
        return "/icons/pay.png";
      }

      if (this.order.status === "cancelled") {
        return "/icons/cancel.png";
      }
    },
    dynamicClass() {
      let c = ["order-card-header"];

      if (this.cartMode) {
        c.push("status-waiting");
      } else {
        c.push(`status-${this.order.status}`);
      }

      if (this.isAppend) c.push("append-block");

      return c;
    },
    header() {
      if (this.cartMode) {
        return `${this.$t("card.order")} ${this.number}:`;
      }

      const status = this.order.status;

      if (["unpaid", "paying", "executed"].includes(status)) {
        return this.$t("card.check");
      } else {
        return `${this.$t("card.order")}:`;
      }
    },
    headerMode() {
      if (this.$route.name == "preorder") return "TAKEAWAY";
      if (this.$route.name == "delivery") return "DELIVERY";
      if (this.$route.name == "hotel_room") return "ROOMS";
      return "INSIDE";
    },
    waitingTimeString() {
      if (this.order.waiting_time) {
        if (this.order.waiting_time > 20) {
          return this.$t("card.waiting_time_long");
        } else {
          return `${this.order.waiting_time} ${this.$t("units.minutes")}`;
        }
      }

      return `${this.waitingTime} ${this.$t("units.minutes")}`;
    },
    statusString() {
      if (this.cartMode || this.order.status != "serving") {
        return this.status;
      } else {
        if (this.waitingTime)
          return `${this.$t("card.waiting_time")} ${this.waitingTimeString}`;
      }

      return this.status;
    },
    newDesign() {
      if (this.$route.name == "hotel_room") return true;

      if (this.cartMode) {
        if (this.$route.name != "preorder" && this.$route.name != "delivery") {
          return false;
        }

        return true;
      }

      return this.order.status == "executed" || this.order.status == "paying";
    },
    orderId() {
      if (this.cartMode || !this.order || !this.order.status) return null;

      return this.order._id.substring(20).toUpperCase();
    },
  },
  mounted() {
    this.tickAcceptMinutes();
    this.timerId = setInterval(this.tickAcceptMinutes, 1 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>
