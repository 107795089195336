<template>
  <div class="order-card-list">
    <div
      :class="makeItemClass(item)"
      v-for="(item, index) in filteredList"
      :key="index"
    >
      <div class="d-flex justify-content-between align-items-baseline">
        <b class="title color-black">
          <img
            src="/icons/alarm.png"
            alt="Not Available"
            style="margin-top:-2px"
            v-if="shouldAlarmItems && !item.available"
          />
          {{ getProductDisplayName(item) }}&nbsp;
          <span class="additional" v-if="item.variant && item.variant !== '*'"
            >({{ getVariantDisplayName(item) }})</span
          >
        </b>
        <span
          class="quantity"
          v-if="
            !editable &&
              !internalCategories.includes(item.category) &&
              item.price
          "
          >{{ getItemQuantity(item) }}</span
        >
        <span class="price color-grey" v-if="item.price"
          >{{ item.price }}{{ currency.symbol }}</span
        >
        <span class="price free color-grey" v-if="!item.price">{{
          $t("list.free")
        }}</span>
      </div>
      <div
        class="modificators"
        v-for="mod in item.modificators"
        :key="mod.name"
      >
        <ul>
          <li class="color-black">
            <span class="title">
              + {{ getModificatorDisplayName(mod.name) }}
            </span>
            <span class="float-right color-grey"
              >{{ mod.price }}{{ currency.symbol }}</span
            >
          </li>
        </ul>
      </div>

      <div class="edit-actions" v-if="editable">
        <img
          :style="{ visibility: freeze ? 'hidden' : 'visible' }"
          :src="
            item.quantity <= 1
              ? '/icons/cross-red.svg'
              : '/icons/btn-minus-black.svg'
          "
          :class="item.quantity <= 1 ? 'btn-del' : 'btn-minus'"
          @click="changeQuantity(index, -1)"
        />

        <span class="color-black">{{ getItemQuantity(item) }}</span>
        <img
          :style="{ visibility: freeze || item.roll_id ? 'hidden' : 'visible' }"
          src="/icons/btn-plus-black.svg"
          class="btn-plus"
          @click="changeQuantity(index, 1)"
        />
      </div>
    </div>
    <div class="list-item" v-for="(item, index) in virtual" :key="index">
      <b class="title color-black">{{ item.name }}&nbsp;</b>
      <span class="price color-grey">{{ item.price }}</span>
    </div>
  </div>
</template>

<style scoped>
.order-card-list {
  padding: 0;
  background: #ededed;
  user-select: none;
}

 

.title {
  display: inline-block;
  line-height: initial;
  width: 60%;
  font-size: 2.5vh;
  line-height: 1;
  font-weight: 400 !important;
  padding-right: 0.5vw;
}
.quantity {
  margin-top: 0;
  width: 6vh;
  padding-right: 1vh;
  line-height: 1;
  text-align: right;
  font-size: 2.3vh;
  color: black;
}

.price {
  width: fit-content;
  float: right;
  text-align: right;
  font-weight: 500;
  line-height: 1;
  font-size: 3.7vh;
}
.price.free {
  font-size: 6vw;
}

hr {
  width: 90vw;
  padding: 0;
  margin: 2.5vh 0 0 0;
}

.modificators li {
  margin-left: 3vw;
  padding: 1vh 0 0 0;
  font-size: 2.2vh;
  display: flex;
  position: relative;
}

.modificators span.title {
  position: relative;
  max-width: 80%;
}

.modificators span {
  font-size: 1.8vh;
  line-height: 1;
  right: 0;
  margin-right: 0.3vw;
  position: absolute;
}

span.additional {
  display: block;
  padding-top: 1vh;
  font-size: 2.2vh;
  line-height: initial;
}

.modificators ul {
  list-style-type: none;
  margin-left: -5px;
  padding: 0;
  margin-bottom: 0;
}

.edit-actions {
  display: flex;
  align-items: center;
  margin: 2.3vh auto 0;
  justify-content: space-between;
  width: 85%;
}

.edit-actions span {
  font-size: 2.8vh;
  font-weight: 500;
  line-height: normal;
}

.btn-plus,
.btn-minus,
.btn-del {
  width: auto;
  height: 2vh;
  cursor: pointer;
}

.list-item {
  border-bottom: 1px solid #a4a3a1;
  padding: 2.5vh 5.4vw 2.5vh;
  position: relative;
}

h6 {
  font-weight: 500;
  font-size: 18px;
}

.btn-minus svg.fa-times {
  color: #129b81 !important;
}

.not-available {
  text-decoration: line-through;
  color: #129b81;
}

@media screen and (min-width: 900px) {
  .list-item {
    padding: 1.7vh 3.3vh;
    display: block;
  }

  .title {
    font-size: 2vh;
    width: fit-content !important;
  }
  .price {
    font-size: 3vh;
    float: right;
  }
  .modificators li {
    margin-left: 0;
    padding: 1.1vh 0 0 2vh;
    font-size: 2vh;
  }
  span.additional {
    display: inline;
  }
  .quantity {
    left: 50vh;
    font-size: 2vh;
    position: sticky;
  }
  .modificators span {
    font-size: 2vh;
    position: relative;
    margin-right: 0;
    right: 0;
  }
}
@media screen and (min-width: 1024px) {
  .order-card-list {
    max-height: none;
  }
  .list-item {
    padding: 2.2222vh 1.666vh;
  }
  .price {
    font-size: 2.7777777777777777vh;
    line-height: 70%;
  }
  .price.free {
    font-size: 2.7777777777777777vh;
    line-height: 70%;
  }
  .title {
    font-size: 2.2222222222222223vh;
    line-height: 2.5555555555555554vh;
    width: 23vh;
  }
  .modificators span {
    font-size: 1.5555555555555556vh;
    line-height: 1.7777777777777777vh;
  }
  .modificators li {
    padding: 1.6666666666666667vh 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .modificators ul {
    margin-left: 0;
  }
  .edit-actions span {
    font-size: 2.6666666666666665vh;
    line-height: 3.111111111111111vh;
  }
  .btn-minus {
    width: 4.444444444444445vh;
  }
  .btn-plus {
    width: 4.444444444444445vh;
  }
  .edit-actions {
    padding: 3.33333vh 8.88888888888889vh 0;
    margin: 0 !important;
    width: 100%;
  }
  .quantity {
    position: static;
  }
}
</style>

<script>
import OrderHelpers from "@/services/order/helpers.js";

export default {
  props: {
    list: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    billTotal: {
      type: Number,
      default: null,
    },
    status: String,
    freeze: {
      type: [Boolean, Number],
      default: false,
    },
    virtual: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeQuantity(index, delta) {
      this.$emit("changeQuantity", index, delta);
    },
    makeItemClass(item) {
      return [
        "list-item",
        this.shouldAlarmItems && !item.available ? "not-available" : "",
        this.editable ? "list-item d-block" : "list-item",
      ];
    },

    getVariantDisplayName(item) {
      const variant_names = item.variants.map(v => v.name);
      const translated_names = variant_names.map(s => {
          return this.$store.getters["menu/localize"]("group_items", s);
      });
      return translated_names.join(',');
    },
    getModificatorDisplayName(mod) {
      return this.$store.getters["menu/localize"]("group_items", mod);
    },
    getProductDisplayName(product) {
      if (product.metatype == "tips") {
        return this.$t("list.tips");
      }
      if (product.metatype == "cutlery") {
        return this.$t("list.cutlery");
      }
      return this.$store.getters["menu/localize"]("products", product.name);
    },
    getItemQuantity(item) {
      if (item.weight_flag == 1) {
        let result_weight = Math.round(item.weight * item.quantity * 100) / 100;
        let target_unit = item.unit;

        if (result_weight >= 1000) {
          result_weight = Math.round((result_weight / 1000) * 100) / 100;
          target_unit = item.unit == "g" ? "kg" : "l";
        }

        return `${result_weight}${this.$t("units." + target_unit)}`;
      } else {
        return `${item.quantity}x`;
      }
    },
  },
  computed: {
    total() {
      if (this.billTotal) {
        return this.billTotal;
      }

      return OrderHelpers.computeTotal(this.list);
    },
    shouldAlarmItems() {
      if (this.editable) {
        return false;
      }

      if (this.status && this.status === "executed") {
        return false;
      }

      return true;
    },
    filteredList() {
      return this.list.filter((item) => {
        if (!item.available && this.status === "executed") {
          return false;
        }

        return true;
      });
    },
    currency() {
      return this.$store.state.currency.current;
    },
    internalCategories() {
      return ["Delivery", "Package", "QRWaiter"];
    },
  },
};
</script>
