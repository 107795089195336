<template>
  <div class="alco-modal-wrapper">
    <div class="hide-content"></div>
    <div class="alco-modal-content">
        <div class="alco-subtitle color-grey">
            {{ $t('alcoholic_modal.subtitle') }}
        </div>
        <img class="alco-image" src="/icons/geo-hero.svg">
        <div class="alco-text color-white" v-html="$t('alcoholic_modal.text')">
        </div>
        <LongButton variant="primary" @click="onConfirm">
            {{ $t('alcoholic_modal.confirm') }}
        </LongButton>
        <LongButton variant="danger" @click="onCancel" class="alco-cancel-button">
            {{ $t('alcoholic_modal.cancel') }}
        </LongButton>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
        },
        onCancel() {
            this.$emit('cancel');
        }
    },
    mounted() {
        this.toggleGlobalScrolling(true);
    },
    beforeDestroy() {
        this.toggleGlobalScrolling(false);
    }
};
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.alco-modal-content {
  position: fixed;
  top: 50%;
  width: 90vw;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5vh 5.4vw 3.7vh;
  text-align: center;
  background: #1d1d1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.3vh 2.3vh 10vw 10vw;

  z-index: 10;
}

.alco-image {
    width: 20vh;
    margin-top: 1vh;
}

.alco-text {
    margin-top: 1vh;
}

.alco-cancel-button {
    margin-top: 2.5vh;
}
</style>