<template>
  <div class="menu-switcher-desktop">
    <div
      v-for="(menu, index) in menus"
      :key="menu._id"
      :class="{ 'menu-tab-desktop': true, 'active-tab': current == index }"
      @click="$emit('switch', index)"
    >
      {{ menu.tag || index + 1 }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menus: {
      type: Array,
    },
    current: {
      type: Number,
    },
  },
};
</script>
<style scoped>
.menu-switcher-desktop {
  width: 100%;

  padding: 2.222vh 11.111vh;
  white-space: nowrap;
  overflow-x: scroll;
  backdrop-filter: blur(20px);
  background: rgba(237, 237, 237, 0.7);
  position: fixed;
  top: 6.222222222222222vh;
  left: 0;
  z-index: 10;
}
.menu-switcher-desktop::-webkit-scrollbar {
  height: 0;
}
.menu-tab-desktop {
  display: inline-block;
  padding: 1.333vh 2.555vh;
  font-size: 1.555vh;
  line-height: 1.777vh;
  color: #1d1d1b;
  width: max-content;
  border: 0.1111vh solid #1d1d1b;
  border-radius: 0.7777777777777778vh;
  margin-right: 1.1111111111111112vh;
  cursor: pointer;
  min-width: 14.222222222222221vh;
  text-align: center;
}
.menu-tab-desktop:last-child {
  margin-right: 0;
}
.active-tab {
  color: #ededed;
  background: #1d1d1b;
}
</style>
