<template>
  <div class="product-list-container">
    <!-- <div class="special-list top-dishes-list" v-if="topDishes && topDishes.length">
      <h3>{{ $t('menu.where_to_start') }}</h3>
      <div id="menuTopList" class="overflow-auto">
        <div class="exclusive-scroll" id="menuExclusiveScroll">
          <div
            class="space"
            v-for="item in topDishes"
            :key="item._id"
            @click="selectExclusive(item)"
          >
            <SpecialProductCard :product="item" />
          </div>
        </div>
      </div>
      <RemoteScroller
        class="exclusive-remote-scroller"
        :id="`menuTopList`"
        v-if="render_remote_scrollers && !isMobile && exclusives.length > 0"
      />
    </div>-->

    <!-- <div class="product-list" v-if="topDishes && topDishes.length">
      <span class="show-all" @click="select(cat)">{{ $t('menu.show_all') }}</span>
      <h3 class="top-dishes-title">{{ $t('menu.where_to_start') }}</h3>

      <div class="horizontal-list" :id="`menuProductListTop`">
        <div
          class="single-horizontal-product"
          v-for="(product,product_index) in topDishes"
          @click="selectProduct(product)"
          :key="`${product._id}-${product_index}`"
        >
          <HorizontalProductCard :product="product" hide_description class="w-mincontent" />
        </div>
      </div>

      <RemoteScroller class="category-remote-scroller top-dish-scroller" :id="`menuProductListTop`" v-if="!isMobile" />
    </div> -->

    <div class="special-list" v-if="exclusives.length > 0">
      <!-- <h3>{{ $t('menu.exclusives') }}</h3> -->
      <div id="menuExclusiveList" class="overflow-auto">
        <div class="exclusive-scroll" id="menuExclusiveScroll">
          <div
            class="space"
            v-for="item in inStockExclusives"
            :key="item._id"
            @click="selectExclusive(item)"
          >
            <SpecialProductCard :product="item" />
          </div>
        </div>
      </div>
      <!-- <RemoteScroller
        class="exclusive-remote-scroller"
        :id="`menuExclusiveList`"
        v-if="render_remote_scrollers && !isMobile && exclusives.length > 0"
      /> -->
    </div>

    <div class="product-list" v-for="(cat,idx) in notEmptyCategories" :key="idx">
      <!-- <span class="show-all" @click="select(cat)">{{ $t('menu.show_all') }}</span> -->
      <h3 :class="isDarkMode ?'dark':''">{{ getCategoryDisplayName(cat) }}</h3>

      <div class="horizontal-list" :id="`menuProductList${idx}`">
        <div
          class="single-horizontal-product"
          v-for="(product,product_index) in filterProductsForCategory(cat)"
          @click="selectProduct(product)"
          :key="`${product._id}-${product_index}`"
        >
          <HorizontalProductCard :product="product" :hide_description="true" class="w-mincontent" />
        </div>
      </div>

      <!-- <RemoteScroller
        class="category-remote-scroller"
        :id="`menuProductList${idx}`"
        v-if="!isMobile"
      /> -->
    </div>

    <!-- <div class="item-category" v-for="cat,idx in categories" :key="cat.name" @click="select(idx)">
      <img :src="cat.image" v-if="cat.image" alt="image category" class="img-category" />
      <h4>{{ getCategoryDisplayName(cat) }}</h4>
      <ul>
        <li v-for="item in randomCategoryList(cat.name)">{{ getProductDisplayName(item) }}</li>
      </ul>
      <p>{{ $t('menu.show_more') }}</p>
    </div>-->
  </div>
</template>


<script>
function shuffleArray(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

import HorizontalProductCard from "@/components/menu/HorizontalProductCard.vue";
import SpecialProductCard from "@/components/menu/SpecialProductCard.vue";

import RemoteScroller from "@/components/RemoteScroller.vue";

import RecommendationsService from "@/services/recommendations";

export default {
  data() {
    return {
      special: true,
      render_remote_scrollers: false
    };
  },
  props: {
    categories: Array,
    exclusives: Array,
    products: Array,
    target: Object,
  },
  components: {
    HorizontalProductCard,
    SpecialProductCard,
    RemoteScroller
  },
  methods: {
    select(cat) {
      this.$emit("select", cat);
    },
    selectProduct(p) {
      this.$emit("selectProduct", p);
    },
    selectExclusive(item) {
      this.$emit("selectExclusive", item);
    },
    filterProductsForCategory(category) {
      return this.products.filter(
        item => item.category_id == category._id && item.stock
      );
    },
    randomCategoryList(category) {
      const categoryProducts = this.products.filter(
        p => p.category === category && p.stock
      );

      const shuffled = shuffleArray(categoryProducts);

      return shuffled.slice(0, 3);
    },
    getCategoryDisplayName(cat) {
      
      const name = this.$store.getters["menu/localize"]("categories", cat.name);

      return name;

      // if (name.length < 19) return name;

      // return name.substring(0, 17) + "...";
    },
    getProductDisplayName(product) {
      return this.$store.getters["menu/localize"]("products", product.name);
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.render_remote_scrollers = true;
    //   }, 150);
    // });
  },
  computed: {
    notEmptyCategories() {
      return this.categories.filter(c => {
        //djigit
        if (`${this.target.cafe_id}` == `126`) {
          return (
            this.products.filter(p => p.category == c.name && p.stock && !p.is_exclusive).length >
              0 && c.name != "exclusives"
          );
        }
        //

        return (
          this.products.filter(p => p.category_id == c._id && p.stock).length >
            0 && c.name != "exclusives"
        );
      });
    },
    inStockExclusives() {
      return this.exclusives.filter(p => p.stock);
    },
    exclusiveCategory() {
      return this.categories.find(c => c.name == "exclusives");
    },
    topDishes() {
      return RecommendationsService.getTopProducts(this.products, 10);
    },
    hideCategoryName() {
      return this.target && `${this.target.cafe_id}` == `126`; 
    }
  }
};
</script>

<style scoped>
.space:last-child {
  padding-right: 2.5vh;
}

.exclusive-scroll {
  display: flex;
  padding:0.5vw 0 0 0;
  user-select: none;
}

h3 {
  display: inline;
  padding: 0 0.5vw 0 5.5vw;
  position: relative;
  max-width: 100%;
  margin: 0;
  font-size: 6vw;
  /* color: #1d1d1b; */
  color: #a4a3a1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-list h3 {
  font-size: 3vh;
  width: 100%;
  padding: 0 2.7vh;
  display: block;
}

.product-list h3.dark {
  color:#908F8D
}

.special-list h3 {
  max-width: none;
  display: inline-block;
}

 

.product-list-container {
  overflow-x: hidden;
  position: relative;
}

.product-list,
.special-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  position: relative;
}

.product-list {
  padding-top: 2.5vh;
}

.top-dishes-list {
  /* margin-bottom: 1.5vh; */
}

/* .special-list {
  display: flex;
  overflow: auto;
  margin-bottom: -2.3vh;

  position: relative;
} */

.horizontal-list {
  display: flex;
  padding: 0 0 0 2.7vh;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  user-select: none;
}

.show-all {
  color: #262626;
  right: 0;
  position: absolute;
  padding: 0.6vh 5.5vw 0 0;
  font-size: 4vw;
  font-weight: 400;
  z-index: 1;
}

.item-category {
  animation: slide-top 0.5s;
  background: #ffffff;
  box-shadow: 0px 10px 14px #f2f2f2;
  border-radius: 40px;
  width: 90%;
  padding-bottom: 10px;
  margin: 20px auto;
}

.top-dish-scroller {
  top: 1.5vh !important;
}

@keyframes slide-top {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

p,
li {
  color: #999999;
  font-weight: 300;
}

.item-category ul {
  padding-left: 30px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
  padding: 15px 0 0 15px;
}

.item-category p {
  text-align: right;
  padding-right: 15px;
}

.img-category {
  width: 100%;
  border-radius: 40px 40px 0px 0px;
}

.category-remote-scroller {
  position: absolute;
  top: 6.5vh;
  right: 2.5vh;
}

.exclusive-remote-scroller {
  position: absolute;
  top: 3.8vh;
  right: 2.5vh;
}

.top-dishes-title {
  font-size: 2.5vh !important;
}

.single-horizontal-product {
  padding-right: 2.5vh;
}

@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .product-list:first-child {
    padding-top: 0;
  }

  .w-mincontent {
    width: min-content;
  }

  .single-horizontal-product {
    margin-left: 2.2vh;
    width: min-content;
  }

  .single-horizontal-product:first-child {
    margin: 0;
  }

  .product-list-container {
    padding: 0 0 5.5vh;
  }
  .product-list {
    padding-top: 4.5vh;
  }

  .space:first-child {
    padding: 0;
  }

  .space {
    padding-left: 3.3vh;
  }

  .product-list-container h3 {
    font-size: 4.3vh;
    display: block;
    padding: 0 0 0 5.5vh;
  }
  .product-list h3 {
    position: relative;
    font-size: 3vh;
    max-width: 105vh;
    padding: 0 5.5vh;
  }
  .show-all {
    display: none;
  }
  .special-list {
    padding-top: 0;
    margin-bottom: 0;
  }
  .special-list h3 {
    font-size: 4.3vh;
    position: relative;
    padding: 0 0 0 5.5vh;
  }

  .exclusive-scroll {
    animation: slide 1s;
    padding: 2.2vh 0 0 5.5vh;
  }
  .horizontal-list {
    animation: slide 1s;
    padding: 2.2vh 5.6vh 0;
  }

  @keyframes fade {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 0.5;
      transform: translateX(10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
</style>