<template>
  <div :class="{
    'client-name': true,
    [`client-name-${systemColor}`]: true
  }">
    <h4>{{ clientName }}</h4>
  </div>
</template>

<script>
export default {
  props: ["clientName", "systemColor"],
};
</script>

<style scoped>
.client-name {
  width: 100%;
  border-top: 1px solid #908F8D;
  background: #1d1d1b !important;
  color: #ededed !important;
  padding: 1.3vh 2.5vh;
}

.client-name h4 {
  font-size: 3vh;
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #aeccc6;
  line-height: 0.8;
}

.client-name-inside {
  background: #1d1d1d !important; 
}

.client-name-inside h4 {
  color: #ededed !important;
}

.client-name-preorder h4 {
  color: #ffd2a8 !important;
}

.client-name-delivery h4 {
  color: #cbd9e6 !important;
}

.client-name-hotels {
  background: #ededed !important;
  color: #1d1d1d !important;
}

.client-name-hotels h4 {
  color: #1d1d1d !important;
}

@media screen and (min-width: 768px) {
  .client-name {
    padding: 0.7vw 1.389vw;
  }
  .client-name h4 {
    font-size: 1.736vw;
    line-height: 85.69%;
  }
}
</style>