<template>
  <div class="category-list-desktop-conatiner">
    <div
      id="category-list-desktop"
      :class="{ 
        'category-list-desktop': true, 
        'small-width': is_cart_active,
        'no-menu-switcher': !isMenuSwitcherShown
      }"
    >
      <div class="mb-20" v-if="inStockExclusives.length">
        <div class="row-desktop">
          <ScrollButtonDesktop
            elementId="exclusivesScrollContainer"
            :count="inStockExclusives.length"
            :changeNumber="menu_changes_count"
          />
          <ScrollButtonDesktop
            elementId="exclusivesScrollContainer"
            :count="inStockExclusives.length"
            :changeNumber="menu_changes_count"
            :right="true"
          />
          <div class="border-cuter">
            <div class="scroll-container " id="exclusivesScrollContainer">
              <div class="product-container">
                <SpecialProductCardDesktop
                  v-for="item in inStockExclusives"
                  :key="item._id"
                  :product="item"
                  @click="openProduct(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-20" v-for="(cat, idx) in notEmptyCategories" :key="idx">
        <div class="category-name">{{ getCategoryDisplayName(cat) }}</div>
        <div class="row-desktop">
          <ScrollButtonDesktop
            :count="filterProductsForCategory(cat).length"
            :elementId="`cl${idx}`"
            :changeNumber="menu_changes_count"
            class="arrow-center"
          />
          <ScrollButtonDesktop
            :count="filterProductsForCategory(cat).length"
            :elementId="`cl${idx}`"
            :changeNumber="menu_changes_count"
            class="arrow-center"
            :right="true"
          />
          <div class="border-cuter">
            <div class="scroll-container" :id="`cl${idx}`">
              <div class="product-container">
                <CategoryProductCardDesktop
                  v-for="(product, product_index) in filterProductsForCategory(
                    cat
                  )"
                  :key="`${product._id}-${product_index}`"
                  :product="product"
                  @click="openProduct(product)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpecialProductCardDesktop from "./SpecialProductCardDesktop.vue";
import CategoryProductCardDesktop from "./CategoryProductCardDesktop.vue";
import ScrollButtonDesktop from "./ScrollButtonDesktop.vue";
// import DishPopUpDesktop from "./DishPopupDesktop.vue";
import OrderCart from "../OrderCart.vue";
import SystemPopUpDesktop from "./SystemPopupDesktop.vue";
import CafePageDesktop from "./CafePageDesktop.vue";

export default {
  data() {
    return {
      is_dish_popup_active: false,
      selected_product: null,
      is_cart_active: false,
      
      //this value increments every time user changes menu
      //because scrollbuttondesktop watch this value
      //if it changes, they re-check the scroll values
      menu_changes_count: 0
    };
  },
  components: {
    SpecialProductCardDesktop,
    CategoryProductCardDesktop,
    ScrollButtonDesktop,
    // DishPopUpDesktop,
    OrderCart,
    SystemPopUpDesktop,
    CafePageDesktop,
  },
  props: {
    exclusives: Array,
    categories: Array,
    products: Array,
    target: Object,
    cafe: Object,
    isMenuSwitcherShown: Boolean,
    is_cart_active: { type: Boolean, default: false },
  },
  watch: {
    products() {
      this.menu_changes_count++;
    }
  },
  methods: {
    closeDishPopUp() {
      this.is_dish_popup_active = false;
      this.selected_product = null;
    },
    openProduct(product) {
      // this.selected_product = product;
      // this.is_dish_popup_active = true;
      this.$emit("selectProduct", product);
    },
    filterProductsForCategory(category) {
      return this.products.filter(
        (item) => item.category_id == category._id && item.stock
      );
    },
    getCategoryDisplayName(cat) {
      const name = this.$store.getters["menu/localize"]("categories", cat.name);

      return name;

      // if (name.length < 19) return name;

      // return name.substring(0, 17) + "...";
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    },
    inStockExclusives() {
      return this.exclusives.filter((p) => p.stock);
    },
    notEmptyCategories() {
      return this.categories.filter((c) => {
        //djigit
        if (`${this.target.cafe_id}` == `126`) {
          return (
            this.products.filter(
              (p) => p.category == c.name && p.stock && !p.is_exclusive
            ).length > 0 && c.name != "exclusives"
          );
        }
        //

        return (
          this.products.filter((p) => p.category_id == c._id && p.stock)
            .length > 0 && c.name != "exclusives"
        );
      });
    },
  },
};
</script>
<style scoped>
.category-list-desktop-conatiner {
  width: 100%;
  display: flex;
}
.category-list-desktop {
  width: 100%;
  position: relative;
  padding-top: 9.2vh;
  padding-bottom: 9.2vh;
  transition: width 0.5s;
}
.category-list-desktop.no-menu-switcher {
  padding-top: 2.22vh;
}
.category-list-desktop::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.small-width {
  width: 70.83%;
}
.scroll-container {
  position: relative;
  overflow: scroll;

  border-radius: 1.3333333333333333vh;
  -moz-border-radius: 1.3333333333333333vh;
  -khtml-border-radius: 1.3333333333333333vh;
  -webkit-border-radius: 1.3333333333333333vh;
}
.border-cuter {
  clip-path: border-box;
  border-radius: 1.3333333333333333vh;
  -moz-border-radius: 1.3333333333333333vh;
  -khtml-border-radius: 1.3333333333333333vh;
  -webkit-border-radius: 1.3333333333333333vh;
  margin: 0 11.11111111111111vh;
  width: calc(100% - 22.222222222222222vh);
  overflow: hidden;
}
.product-container {
  display: flex;
  flex-wrap: nowrap;
  border-radius: 1.3333333333333333vh;
  -moz-border-radius: 1.3333333333333333vh;
  -khtml-border-radius: 1.3333333333333333vh;
  -webkit-border-radius: 1.3333333333333333vh;
  width: max-content;
}
.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.category-name {
  font-size: 2.222vh;
  line-height: 2.5555555555555554vh;
  color: #908f8d;
  margin: 0 11.11111111111111vh;
  margin-bottom: 1.1111111111111112vh;
}
.mb-20 {
  margin-bottom: 2.222vh;
}
.row-desktop {
  width: 100%;
  position: relative;
}
.cart-container {
  position: absolute;
  right: 0;
  top: 6.222222222222222vh;
  z-index: 13;
  border-left: 0.1111vh solid #c8c8c8;
  width: 29.17%;
  transform: translateX(100%);
  transition: transform 0.5s;
  min-height: 93.77777777777777vh;
  padding: 2.2222vh;
  box-sizing: border-box;
}
.cart-title {
  font-size: 3.3333333333333335vh;
  line-height: 3.888888888888889vh;
  color: #1d1d1b;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-title img {
  width: 3.3333333333333335vh;
  cursor: pointer;
}
.active-cart {
  transform: translateX(0%);
  transition: transform 0.5s;
}
.scrollable-area {
  height: calc(87.66666666666667vh - 3.888888888888889vh);
  width: 100%;
  overflow: scroll;
}
.scrollable-area::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.empty-cart-panda {
  width: 37.77777777777778vh;
  margin-top: 2.2222vh;
}
.empty-cart-text {
  width: 100%;
  font-size: 2.7777777777777777vh;
  line-height: 3.2222222222222223vh;
  color: #1d1d1b;
  margin-top: 2.2222vh;
}
.large-arrow {
  width: 100%;
  height: 3.111vh;
}

.arrow-center {
  height: 75%;
}

</style>
