<template>
  <div class="remote-scroller" v-show="!hidden" ref="container">
    <div
      :class="{
            'remote-scroller-control': true,
            'remote-scroller-dragging': this.dragging,
            'remote-scroller-exclusive': this.exclusive
        }"
      ref="control"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    scroll_target_id: String,
    limit_id: String,
    exclusive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hidden: false,
      dragging: false,
      position: 0,
      max_width: 0,
      remote_element_width: 0
    };
  },
  methods: {
    onMouseDown(ev) {
      this.dragging = true;
    },
    onMouseUp(ev) {
      this.dragging = false;
    },
    onMouseMove(ev) {
      if (!this.dragging) return;

      const delta = ev.movementX;

      this.position += delta;

      if (this.position < 0) this.position = 0;
      if (this.position > this.max_width) this.position = this.max_width;

      this.$refs.control.style.left = `${this.position}px`;

      const el = document.getElementById(this.scroll_target_id || this.id);

      const width_diff = el.getBoundingClientRect().width;

      const new_scroll_left =
        ((this.position) * (this.remote_element_width - width_diff)) / this.max_width;

      el.scrollLeft = new_scroll_left;
    },
    onRemoteScroll() {
      if (this.dragging) return;

      const el = document.getElementById(this.id);
      const el_scroll = el.scrollLeft;

      this.position =
        (this.max_width * el_scroll) /
        (el.scrollWidth - el.getBoundingClientRect().width);
      this.$refs.control.style.left = `${this.position}px`;
    },
    init() {
      const el = document.getElementById(this.id);

      let window_width = window.screen.width;

      if (this.limit_id) {
        window_width = document.getElementById(this.limit_id).scrollWidth;  
      }

      console.log(`--- ${this.id} ---`);
      console.log(el.scrollWidth);
      console.log(window_width);

      if (el.scrollWidth <= window_width) {
        this.hidden = true;
        return;
      }

      let full_width = el.scrollWidth;

      // const computed_style = window.getComputedStyle(el, null);

      // full_width -= parseInt(computed_style.getPropertyValue("padding-left"));
      // full_width -= parseInt(computed_style.getPropertyValue("padding-right"));

      const container_width = this.$refs.container.getBoundingClientRect()
        .width;

      const control_width = (window_width * container_width) / full_width;

      this.max_width = container_width - control_width;
      this.remote_element_width = full_width;

      this.$refs.control.style.width = `${control_width}px`;

      this.$refs.control.addEventListener("mousedown", this.onMouseDown, false);
      window.addEventListener("mouseup", this.onMouseUp, false);
      window.addEventListener("mousemove", this.onMouseMove, false);
      el.addEventListener("scroll", this.onRemoteScroll);
    }
  },
  mounted() {
      setTimeout(this.init, 150);
  },
  beforeDestroy() {
    if (this.$refs.control) this.$refs.control.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("mousemove", this.onMouseMove);

    const el = document.getElementById(this.id);
    if (el) el.removeEventListener("scroll", this.onRemoteScroll);
  }
};
</script>

<style>
.remote-scroller {
  border-radius: 12px;
  width: 30vw;
  background: #a4a3a1;
  height: 1.4vh;

  position: relative;

  z-index: 1;
}

.remote-scroller-control {
  position: absolute;
  height: 1.4vh;
  background: #1d1d1b;
  border-radius: 12px;
  /* width: 3vw; */
  transition: 0.1s;
  cursor: pointer;
}

.remote-scroller-control:hover,
.remote-scroller-dragging {
  transition: 0.1s;
  height: 2vh;
  top: -0.3vh;
}

.remote-scroller-exclusive {
  background: #ededed;
}
</style>