<template>
  <div
    class="services-space"
    v-if="
      showServices && !specialServiceButton && menus.length == 1 && showServices
    "
  >
    <div class="services-btn" v-thematic @click="$emit('servicesClicked')">
      <img
        :src="
          isDarkMode
            ? '/icons/services-icon-only-white.svg'
            : '/icons/services-icon-only.svg'
        "
      />
      {{ $t('navbar.services') }}
    </div>
  </div>

  <div class="menu-tabs" v-else>
    <!-- <div :class="getTabClass(0)" @click="doTheSwitch(0)">
        {{ $t('switcher.primary') }}
    </div>-->
    <div class="space">
      <div
        class="red-call-service-button"
        :class="{
          disabled: call_waiter_timeout > 0,
        }"
        @click="$emit('specialServiceButtonClicked')"
        v-if="showServices && specialServiceButton"
      >
        <img src="/icons/bell-bold.svg" alt="" />
        Call waiter
        <template v-if="call_waiter_timeout"
          >({{ call_waiter_timeout }} s.)</template
        >
      </div>

      <img
        class="services-icon"
        :src="
          isDarkMode
            ? '/icons/services-icon-white.svg'
            : '/icons/services-icon.svg'
        "
        @click="$emit('servicesClicked')"
        v-else-if="showServices && !specialServiceButton"
      />
      <template v-if="menus.length > 1">
        <div
          v-for="(m, index) in menus"
          :key="m._id"
          :class="[getTabClass(index), menus.length <= 2 ? 'wide' : '']"
          v-thematic
          @click="doTheSwitch(index)"
        >
          {{ getDisplayTitle(m) || `${index + 1}` }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: Array,
    current: Number,
    showServices: Boolean,
    specialServiceButton: Boolean,
    call_waiter_timeout: Number,
  },
  methods: {
    doTheSwitch(index) {
      this.$emit("switch", index);
    },
    getTabClass(index) {
      return this.current == index ? "tab active" : "tab";
    },
    getDisplayTitle(menu) {
      const locale = this.$i18n.locale;

      if (locale == menu.original_locale) return menu.tag;

      if (!menu.tag) return null;

      const translated =
        menu.tag_i18n && menu.tag_i18n[locale]
          ? menu.tag_i18n[locale]
          : menu.tag;

      return translated || null;
    },
  },
};
</script>

<style scoped>
.menu-tabs {
  overflow: auto;
  position: relative;
  padding: 2.5vh 0 0 2vh;
  width: 100%;
  display: -webkit-box;
  overflow: scroll;
  padding: 2.5vh 0;
  width: auto;
  margin: 0vh auto;
}

::-webkit-scrollbar {
  display: none;
}

.tab {
  box-shadow: 0px 1px 7px #dadada;
  border-radius: 5vh;
  border: 1px solid #1d1d1b;
  color: #1d1d1b;
  text-align: center;
  min-width: 19vh;
  margin: 0 0 0 2.5vh;
  padding: 0.8vh 2vh;
}

.tab.wide {
  min-width: 19.5vh;
}

.tab.dark {
  box-shadow: none;
  background: transparent;
  border: 1px solid #ededed;
  color: #ededed;
}

.space {
  display: flex;
  padding-right: 2.5vh;
  width: max-content;
  justify-content: space-evenly;
}

.tab.active {
  background: #1d1d1b;
  color: #ededed;
}

.tab.active.dark {
  background: #ededed;
  color: #1d1d1b;
}

.services-icon {
  margin-left: 2.5vh;
}

.red-call-service-button {
  background: #f5273f;
  border-radius: 30px;

  font-size: 14px;
  line-height: 16px;

  font-family: ChadwickBold;

  padding: 8px 16px 8px 14px;

  display: flex;
  column-gap: 4px;

  align-items: center;
  align-content: center;
  align-self: center;

  color: #f5f5f5;

  margin-left: 2.5vh;

  font-weight: bold;
}

.red-call-service-button img {
  display: block;
  width: 20px;
  height: 20px;
}

.red-call-service-button.disabled {
  opacity: 0.5;
}

.services-space {
  width: 100%;
  padding: 2.5vh 0;
}

.services-btn {
  width: calc(100% - 2.5vh * 2);
  padding: 17px 0;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 1px solid #1d1d1b;
  color: #1d1d1b;
  border-radius: 30px;
  margin: 0 2.5vh;
  box-sizing: border-box;
}

.services-btn.dark {
  color: #ededed;
  border-color: #ededed;
}

.services-btn img {
  margin-right: 8px;
}
</style>