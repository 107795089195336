<template>
  <div
    :class="{
      'inside-status-bar': true,
      [currentWrapperClass]: true,
    }"
  >
    <div class="status-bar-icon">
      <img :src="currentIcon" />
    </div>
    <div class="status-bar-info">
      <h2 class="order-number">{{ $t("card.order") }} {{ index + 1 }}</h2>
      <span class="status-description">{{ statusDescription }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    order: Object,
  },
  data() {
    return {
      acceptedMinutes: 0,
    };
  },
  computed: {
    currentIcon() {
      if (this.isCartMode) {
        return "/icons/statusbar/editing.svg";
      }

      if (["waiting"].includes(this.order.status)) {
        return "/icons/statusbar/waiting.svg";
      }

      if (["unpaid", "paying"].includes(this.order.status)) {
        return "/icons/statusbar/unpaid.svg";
      }

      if (this.order.status === "serving") {
        return "/icons/statusbar/serving.svg";
      }
      if (this.order.status === "serving" && this.order.served_at) {
        return "/icons/statusbar/served.svg";
      }

      if (this.order.status == "executed") {
        return "/icons/statusbar/paid.svg";
      }

      if (this.order.status === "cancelled") {
        return "/icons/statusbar/cancelled.svg";
      }
    },
    currentWrapperClass() {
      if (this.isCartMode) return "status-cart";
      return `status-${this.order.status}`;
    },
    statusDescription() {
      if (this.isCartMode) return this.$t("card.status_cart");

      if (this.order.status == "waiting") return this.$t("card.status_waiting");

      if (this.order.status === "serving") {
        if (this.order.served_at) {
          return this.$t("card.status_served");
        }

        if (this.acceptedMinutes < 1) {
          return this.$t("card.status_serving_recent");
        } else {
          return this.$t("card.status_serving", {
            mins: this.acceptedMinutes,
          });
        }
      }

      if (this.order.status === "cancelled") {
        return this.$t("card.status_cancelled");
      }

      if (this.order.status === "unpaid") {
        return this.$t("card.status_unpaid");
      }

      if (this.order.status === "paying") {
        return this.$t("card.status_paying");
      }

      if (this.order.status === "executed") {
        return this.$t("card.status_executed");
      }
    },
    isCartMode() {
      return !this.order || !this.order.status;
    },
  },
};
</script>

<style scoped>
.inside-status-bar {
  background: #1d1d1b;
  color: #ededed;
  padding: 1.3vh;
  width: 100.2%;
  display: flex;
  align-content: center;
  align-items: center;
}

.status-bar-info {
  display: flex;
  flex-flow: column;
}

.status-cart {
  color: #ffd2a8;
}

.status-unpaid,
.status-paying {
  color: #ededed;
}

/* .status-executed {
  color: #129b81;
} */

/* .status-cancelled {
  color: #e2223b;
} */

.status-bar-icon {
  margin-right: 1.2vh;
}

.status-bar-icon img {
  width: auto;
  height: 5vh;
}

.order-number {
  font-size: 2.6vh;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.status-description {
  font-size: 1.8vh;
}
</style>