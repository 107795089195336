<template>
  <div @click="$emit('click')" class="category-product-card-desktop">
    <img :src="productImage" alt="" class="product-image" />
    <div class="product-info-block">
      <div class="row-space-between">
        <div class="product-name">{{ productDisplayName }}</div>
        <div class="product-price">{{ firstPrice }}{{currency.symbol}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: Object
  },
  computed: {
    productDisplayName() {
      return this.$store.getters["menu/localize"](
        "products",
        this.product.name
      );
    },
    productDisplayDescription() {
      return this.$store.getters["menu/localize"](
        "descriptions",
        this.product.description
      );
    },
    productIngridientsList() {
      return this.$store.getters["menu/localize"](
        "ingridients",
        this.product.ingridients
      )
        .split(",")
        .slice(0, 3);
    },
    currency() {
      return this.$store.state.currency.current;
    },
    firstPrice() {
      const base_price = this.product.base_price || 0;

      const variants_groups = this.product.price_groups.filter(g => g.single_select);
      
      let variants_add = 0;

      for (let i = 0; i < variants_groups.length; i++) {
        const items = variants_groups[i].items;

        variants_add += items[0].price;
      }

      // if (variants_group && variants_group.items[0] && variants_group.items[0].price) {
      //   return variants_group.items[0].price + base_price;
      // }

      return base_price + variants_add;
    },
    displayPrice() {
      if (!this.promotionDiscount) return this.firstPrice;

      return Math.round(this.firstPrice * this.promotionDiscount);
    },
    promotionDiscount() {
      return this.$store.getters['promotions/getProductDiscount'](this.product);
    },
    productImage() {
      if (!this.product.image) {
        return '/icons/no-dish.jpg';
      }

      // if (this.product.image.includes('joinposter.com')) {
      //   return this.product.image.replace('_original', '');
      // }
      
      return this.product.image;
    }
    // shortDisplayName() {
    //   if (this.productDisplayName.length < 15) return this.productDisplayName;

    //   return this.productDisplayName.substring(0,15) + '...';
    // }
  }
};
</script>
<style scoped>
.category-product-card-desktop {
  width: 33.333333333333336vh;
  height: auto;
  /* height: 22.666666666666668vh; */
  margin-right: 1.6666666666666667vh;
  cursor: pointer;
}
.category-product-card-desktop:last-child {
  margin-right: 0;
}
.product-image {
  width: 100%;
  height: 16.666666666666668vh;
  object-fit: cover;
  border-radius: 1.1111vh;
}
.product-info-block {
  padding: 0.7777777777777778vh 0.7777777777777778vh 0;
}
.row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-name {
  font-weight: normal;
  font-size: 2.222vh;
  line-height: 2.5555555555555554vh;
  color: #1d1d1b;
  width: 22vh;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-price {
  font-weight: normal;
  font-size: 2.4444444444444446vh;
  line-height: 2.7777777777777777vh;
  color: #908f8d;
}
</style>
