function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

function recommendationsSorter(productA, productB) {
    const ratingA = productA.rating || 0;
    const ratingB = productB.rating || 0;

    if (ratingA > ratingB) return -1;
    if (ratingA < ratingB) return 1;

    return 0;
}

function getTopProducts(products, _count) {
    const count = _count || 10;

    const in_stock = products.filter(p => p.stock);
    const sorted = in_stock.sort(recommendationsSorter);

    const sliced = sorted.slice(0, count);

    const result = shuffle(sliced);

    return result;
}

//TODO: optimize by caching sorted products
function getForProduct(target, products, already_recommended) {
    const filtered = products.filter(it => {
        if (it.name === target.name && it.category === target.category) {
            return false;
        }

        if (!it.stock) return false;

        if (already_recommended) {
            const found = already_recommended.find(p => p._id == it._id);

            if (!!found) return false;
        }

        return true;
    });

    const sorted = filtered.sort(recommendationsSorter);

    const top = sorted.slice(0, 3);

    const recommendations = shuffle(top);

    console.log(`Built recommendations: sorted = ${sorted.length}, top = ${top.length}`);

    return recommendations;
}

export default {
    getForProduct,
    getTopProducts,
    shuffle
}