<template>
  <div class="modificators-group-wrapper">
    <h5 class="modificators-group-toggle" v-thematic @click="toggleContents()">
      {{ toggleButtonTitle }}
      <!-- <img
        :class="show_contents ? 'toggle-show' : 'toggle-hide'"
        src="/icons/list-arrow.svg"
        v-if="isMobile"
      /> -->
    </h5>

    <div class="modificators-group-items" v-if="show_contents">
      <div
        :class="{
          'modificators-group-item': true,
          active: isItemSelected(item),
        }"
        v-for="(item, index) in group.items"
        :key="`${item._id}-${index}`"
        @click="onItemSelected(item)"
        v-thematic
      >
        <div class="item-title">{{ localizedGroupItemName(item.name) }}</div>
        <div class="item-price" v-if="item.price">
          +{{ item.price }}{{ currency.symbol }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_contents: true,
    };
  },
  props: {
    group: Object,
    selected: Array,
  },
  methods: {
    toggleContents() {
      // if (this.isMobile) this.show_contents = !this.show_contents;
    },
    isItemSelected(target) {
      return !!this.selected.find(
        (item) => item.item_id == target._id || item.name == target.name && item.price == target.price
      );
    },
    onItemSelected(item) {
      const selected_count = this.group.items.filter(g => {
        return this.isItemSelected(g);
      }).length;

      const will_be_deleted = this.isItemSelected(item);

      console.log(selected_count, this.group.max_count);

      if (!will_be_deleted && selected_count >= this.group.max_count) return;

      const copy = Object.assign({}, item);

      copy.group_id = this.group._id;
      copy.group_name = this.group.name;

      this.$emit("toggle", copy);
    },
    localizedGroupItemName(item_name) {
      return this.$store.getters["menu/localize"]("group_items", item_name);
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    toggleButtonTitle() {
      const prefix = this.isMobile ? "" : "+ ";
      if (this.group.name) return prefix + this.localizedGroupName;

      return prefix + this.$t("menu.choose_modificators");
    },
    localizedGroupName() {
      return this.$store.getters["menu/localize"](
        "price_groups",
        this.group.name
      );
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.show_contents = true;
    }
  },
};
</script>

<style scoped>
.modifcators-group-wrapper {
}

.modificators-group-toggle {
  font-weight: 500;
  margin: 3.7vh 0 0 !important;
  padding: 0 !important;
  color: #a4a3a1;
  font-size: 2.2vh;
}

.toggle-show {
  margin-top: -0.5vh;
  width: auto;
  transition: 0.3s;
  height: 2vh;
  transform: rotate(90deg);
}

.toggle-hide {
  margin-top: -0.5vh;
  width: auto;
  height: 2vh;
  transition: 0.3s;
}

.modficators-group-items {
  position: relative;
  padding-top: 0;
  margin-top: -0.6vh;
  animation: slide 0.3s;
}

.modificators-group-item {
  max-width: 90vw;
  text-overflow: ellipsis;
  padding: 1.3vh 4.7vw 1.3vh 4.7vw;
  margin: 1.9vh 0 0;
  color: #a4a3a1;
  display: flex;
  transition: 0.2s;
  font-size: 5vw;
  font-weight: 500;

  background: transparent;
  border-radius: 10px;
  border: 2px solid #a4a3a1;
}

.modificators-group-item.dark {
  border: 2px solid #a4a3a1;
}

.item-title {
  margin: 0;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 2.3vh;
  padding-right: 6vh;
  max-height: 9vh;
  font-weight: 500;
}

.item-price {
  font-size: 2.3vh;
  align-self: center;
}

.modificators-group-item.active {
  max-width: 90vw;
  color: #1d1d1b;
  box-shadow: none;
  border: 2px solid #129b81;
}

.modificators-group-item.dark.active {
  color: #ededed;
}
@media screen and (min-width: 1024px) {
  .modificators-group-toggle {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
    width: 100%;
    margin-top: 0 !important;
  }
  .modificators-group-wrapper {
    padding-top: 3.3333vh !important;
  }
  .modificators-group-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    widows: 100%;
    justify-content: flex-start;
  }
  .modificators-group-item {
    width: 32.7%;
    padding: 1.4444444444444444vh 1.6666666666666667vh;
    margin-top: 1.6666666666666667vh;
    margin-right: 0.8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.11111vh solid #908f8d;
  }
  .item-price {
    font-size: 2.3333333333333335vh;
    line-height: 2.6666666666666665vh;
  }
  .item-title {
    font-size: 2.3333333333333335vh;
    line-height: 2.6666666666666665vh;
  }
  .modificators-group-item:nth-of-type(3n) {
    margin-right: 0;
  }
  .modificators-group-item.active {
    background-color: #1d1d1b;
    color: #ededed;
    border: 2px solid #1d1d1b;
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
</style>
