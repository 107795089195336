<template>
  <div class="variants-group-wrapper">
    <h6 class="variants-group-name" v-if="group.name">
      {{ localizedGroupName }}:
    </h6>
    <div
      :class="{
        'variants-group-item': true,
        active: isItemSelected(item),
      }"
      v-for="(item, index) in group.items"
      :key="`${item._id}-${index}`"
      @click="onItemSelected(item)"
      v-thematic
    >
      {{
        !!item.name
          ? localizedGroupItemName(item.name)
          : formatWeight(item.weight)
      }}
      <span class="variant-price" v-if="item.price && showPricesAtAll"
        >{{ item.price }}{{ currency.symbol }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: Object,
    selected: Array,
    selectedModificators: Array,
    show_prices: Boolean,
    dish: Object,
    forceNoCheck: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    findSelectedIndex(target, use_modificators) {
      const target_array = use_modificators
        ? this.selectedModificators
        : this.selected;
      return target_array.findIndex(
        // (item) => item.name == target.name && item.price == target.price
        (item) => item._id == target._id
      );
    },
    isItemSelected(target) {
      return this.findSelectedIndex(target) !== -1;
    },
    onItemSelected(target) {
      let copy = Object.assign({}, target);

      copy.item_id = target._id;
      copy.group_name = this.group.name;
      copy.group_id = this.group._id;

      if (this.promotionDiscount) {
        if (this.promotionDiscount)
          copy.discount_price = Math.round(this.promotionDiscount * copy.price);
      }

      if (this.group.max_count == 1) {
        this.group.items.forEach((item) => {
          const index = this.findSelectedIndex(item);

          if (index !== -1) {
            this.$emit("remove", index);
            this.checkShowForNeighbours(item);
          }
        });

        if (!this.isItemSelected(copy)) {
          this.$emit("add", copy);
        }
      } else if (this.group.max_count > 1) {
        const selected_index = this.findSelectedIndex(copy);

        const selected_from_group = this.group.items.filter((it) => {
          return !!this.selected.find((si) => si._id == it._id);
        }).length;

        console.log(selected_from_group);

        if (selected_index !== -1) {
          if (selected_from_group > 1) this.$emit("remove", selected_index);
        } else {
          if (selected_from_group < this.group.max_count)
            this.$emit("add", copy);
        }
      }

      const possible_show_group = this.dish.price_groups.find(
        (g) => g.show_for.includes(target._id)
      );

      if (possible_show_group) {
        let group_was_selected = false;

        possible_show_group.items.forEach((item) => {
          if (group_was_selected) return;

          const index = this.findSelectedIndex(item);

          if (index !== -1) group_was_selected = true;
        });

        if (!group_was_selected && possible_show_group.single_select) {
          this.$emit("add", possible_show_group.items[0]);
        }
      }

      if (this.forceNoCheck) {
        const currentGroupNoItem = this.group.items.find(st =>
            (st.name.includes("NO") || st.name.includes("NIC")) && st.price == 0
          );

        if (!currentGroupNoItem) return;

        if (currentGroupNoItem._id == target._id) {
          this.group.items.forEach((item) => {
            const index = this.findSelectedIndex(item);

            if (index !== -1) {
              this.$emit("remove", index);
              this.checkShowForNeighbours(item);
            }
          });

          this.$emit("add", copy);
        } else {
          if (this.selected.length >= 1) {
            const selected_no = this.findSelectedIndex(currentGroupNoItem);

            if (selected_no !== -1) {
              this.$emit("remove", selected_no);
            }
          }
        }
      }
    },
    checkShowForNeighbours(item) {
      const possible_show_group = this.dish.price_groups.find(
        (g) => g.show_for.includes(item._id)
      );

      if (!possible_show_group) return;

      possible_show_group.items.forEach((group_item) => {
        const index = this.findSelectedIndex(group_item);

        if (index !== -1) {
          this.$emit("remove", index);
        } else {
          const mod_index = this.findSelectedIndex(group_item, true);

          if (mod_index !== -1) this.$emit("removeModificator", mod_index);
        }
      });
    },
    formatWeight(value) {
      const unit =
        this.dish.unit == "g" ? this.$t("units.g") : this.$t("units.l");
      const unit_lg =
        this.dish.unit == "g" ? this.$t("units.kg") : this.$t("units.l");
      if (value > 1000) {
        return `${Math.round((value / 1000) * 100) / 100} ${unit_lg}`;
      } else {
        return `${value} ${unit}`;
      }
    },
    localizedGroupItemName(item_name) {
      return this.$store.getters["menu/localize"]("group_items", item_name);
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    localizedGroupName() {
      return this.$store.getters["menu/localize"](
        "price_groups",
        this.group.name
      );
    },
    promotionDiscount() {
      return this.$store.getters["promotions/getProductDiscount"](this.dish);
    },
    showPricesAtAll() {
      return (
        this.show_prices || this.$store.state.worldwide.region.show_all_prices
      );
    },
  },
};
</script>

<style>
.variants-group-wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.variants-group-name {
  /* margin-top: 2vh; */
  font-size: 2.2vh;
  color: #a4a3a1;
  margin-bottom: 0;
}

.variants-group-item {
  padding: 1.3vh 4.7vw;
  margin: 1.9vh 0 0 0;
  color: #a4a3a1;
  font-size: 2.5vh;
  font-weight: 500;
  transition: 0.3s;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #a4a3a1;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.variants-group-item.active {
  background-color: #1d1d1b;
  color: #ededed;
  border: 1px solid transparent;
}

.variants-group-item.active.dark {
  background: #ededed;
  color: #1d1d1b;
}

.variant-price {
  float: right;
}
@media screen and (min-width: 1024px) {
  .variants-group-name {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
    width: 100%;
    margin-top: 3.3333333333333335vh;
  }
  .variants-group-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .variants-group-item {
    width: 32.7%;
    font-size: 2.3333333333333335vh;
    line-height: 2.6666666666666665vh;
    padding: 1.4444444444444444vh 1.6666666666666667vh;
    margin-top: 1.6666666666666667vh;
    margin-right: 0.81%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .variants-group-item:nth-of-type(3n) {
    margin-right: 0;
  }
}
</style>
