var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pop-up-bg"}),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:{ 'dish-pop-up-desktop': true, 'pb-0': _vm.is_scroll_in_bottom },attrs:{"id":"dish-pop-up-desktop"}},[_c('div',{staticClass:"dish-content-wrapper",attrs:{"id":"dishContentWrapper"}},[_c('img',{class:{ 'scroll-icon': true, 'reverse-arrow': _vm.scroll_top },attrs:{"src":"/icons/desktop/dish-scroll-icon.svg"},on:{"click":function($event){return _vm.scrollContent()}}}),_c('img',{staticClass:"close-icon",attrs:{"src":"/icons/desktop/dish-close-icon.svg"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.productImage,"alt":""}})]),_c('div',{staticClass:"dish-info-block"},[_c('div',{staticClass:"row-space-between"},[_c('div',{staticClass:"product-title"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"product-price"},[_vm._v("\n                "+_vm._s(_vm.uiTotalPrice)+_vm._s(_vm.currency.symbol)+"\n              ")])]),_c('div',{staticClass:"product-description"},[_vm._v(_vm._s(_vm.product.description))]),_c('div',{staticClass:"product-characteristics"},[(_vm.totalWeightDisplayText)?_c('div',{staticClass:"product-characteristic"},[_c('img',{attrs:{"src":"/icons/desktop/product-weight.svg","alt":""}}),_vm._v("\n                "+_vm._s(_vm.totalWeightDisplayText)+"\n              ")]):_vm._e(),(_vm.pricePerUnit)?_c('div',{staticClass:"product-characteristic"},[_vm._v("\n                "+_vm._s(_vm.$t("menu.price_per_unit", {
                    unit: _vm.pricePerUnit.unit,
                  }))+"\n                "+_vm._s(_vm.pricePerUnit.price)+" "+_vm._s(_vm.currency.symbol)+"\n              ")]):_vm._e()])])]),_c('div',{attrs:{"id":"scroll-able-content"}},[(!_vm.added_to_cart)?_vm._l((_vm.product.price_groups || []),function(price_group,index){return _c('div',{key:index,staticClass:"price-group"},[(price_group.single_select)?_c('div',[_c('VariantsGroup',{attrs:{"group":price_group,"dish":_vm.product,"selected":_vm.cartItem.variants,"show_prices":true},on:{"add":function (v) { return _vm.cartItem.variants.push(v); },"remove":function (i) { return _vm.cartItem.variants.splice(i, 1); }}})],1):_c('div',[(price_group.items.length)?_c('ModificatorsGroup',{attrs:{"group":price_group,"selected":_vm.cartItem.modificators},on:{"toggle":_vm.onModificatorToggle}}):_vm._e()],1)])}):_vm._e()],2),_c('div',{class:_vm.is_scroll_in_bottom || !_vm.product.price_groups.length
              ? 'default-position'
              : 'fixed-container'},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableCart),expression:"!disableCart"}],staticClass:"second-row"},[_c('div',{staticClass:"second-row-section",style:({ visibility: 'hidden' })},[_c('button',{staticClass:"add-to-favorites"},[_c('img',{attrs:{"src":"/icons/black-like-outlined.svg","alt":""}}),_vm._v("Add to\n                favourites\n              ")]),(false)?_c('img',{staticClass:"added-to-favourites",attrs:{"src":"/icons/desktop/added-to-favourites-icon.svg"}}):_vm._e()]),_c('div',{staticClass:"flex-justify-content-center second-row-section"},[_c('img',{staticClass:"count-controll-btn",style:({
                  visibility:
                    _vm.cartItem.quantity > 1 && !_vm.added_to_cart
                      ? 'visible'
                      : 'hidden',
                }),attrs:{"src":"/icons/desktop/count-decrement-icon.svg"},on:{"click":function($event){return _vm.changeQuantity(-1)}}}),_c('div',{staticClass:"count-control-counter",style:({
                  visibility: !_vm.added_to_cart ? 'visible' : 'hidden',
                })},[_vm._v("\n                "+_vm._s(_vm.cartItem.quantity)+"x\n              ")]),_c('img',{staticClass:"count-controll-btn",style:({
                  visibility:
                    _vm.cartItem.quantity < _vm.maxPossibleQuantity && !_vm.added_to_cart
                      ? 'visible'
                      : 'hidden',
                }),attrs:{"src":"/icons/desktop/count-increment-icon.svg"},on:{"click":function($event){return _vm.changeQuantity(1)}}})]),_c('div',{staticClass:"second-row-section"},[(!_vm.added_to_cart)?_c('button',{staticClass:"add-to-cart-btn",on:{"click":function($event){return _vm.addToCart()}}},[_vm._v("\n                "+_vm._s(_vm.$t("menu.add_to_cart"))+"\n              ")]):_c('div',{staticClass:"added-to-cart-row"},[_c('img',{attrs:{"src":"/icons/desktop/green-fill-ok-icon.svg","alt":""}}),_vm._v(_vm._s(_vm.$t("menu.added_to_cart"))+"\n                (х"+_vm._s(_vm.cartItem.quantity)+")\n              ")])])]),_c('div',{staticClass:"third-row"},[_c('div',{staticClass:"recomendet-dishes-title"},[_vm._v("\n              "+_vm._s(_vm.$t("menu.recommending"))+"\n            ")]),_c('div',{staticClass:"recomendet-dishes-container"},_vm._l((_vm.recommendations),function(rec){return _c('div',{key:rec._id,staticClass:"recomendet-dish",on:{"click":function($event){_vm.$emit('selectRecommendation', rec),
                    (_vm.is_scroll_in_bottom = false)}}},[_c('img',{staticClass:"recomendet-dish-image",attrs:{"src":rec.image || '/icons/no-dish-exclusive.jpg'}}),_c('div',{staticClass:"recomendet-dish-name"},[_vm._v("\n                  "+_vm._s(rec.name)+"\n                ")]),_c('div',{staticClass:"recomendet-dish-price"},[_vm._v("\n                  "+_vm._s(_vm.getRecommendationPrice(rec))+_vm._s(_vm.currency.symbol)+"\n                ")])])}),0)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }