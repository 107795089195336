<template>
  <div class="inside-card-header">
    <div class="order-id">
      <h3>{{ isHotel ? 'HOTELS' : 'INSIDE'}}</h3>
      <span v-if="order && order.local_id">
        id:
        <h3 class="d-inline">{{ order.local_id }}</h3>
      </span>
    </div>

    <ClientName v-if="clientName" :systemColor="'inside'" :clientName="clientName" />
  </div>
</template>

<script>
import ClientName from "@/components/order-details/ClientName.vue";

export default {
  props: {
    order: Object,
    isHotel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    clientName() {
      if (this.$store.getters["clients/isAuthorized"]) {
        return this.$store.state.clients.client.name;
      }
    },
  },
  components: {
    ClientName,
  },
};
</script>

<style scoped>
.inside-card-header {
  background: #1d1d1b;
  width: 100.2%;
  position: relative;
  border-bottom: 1px solid #908F8D;
  top: -1px;
  margin-bottom: -2px;
  border-top-right-radius: 2.4vh;
  border-top-left-radius: 2.4vh;
}

.header-mode {
  font-size: 3.6vh;
  margin: 2.3vh auto 2vh 2.4vh;
  color: #aeccc6;
  display: inline-block;
}

.order-id {
  color: #ededed;
}

.order-id h3 {
  margin: 0;
}

.order-id span {
  width: 100%;
  text-align: right;
  font-size: 4vw;
}
</style>