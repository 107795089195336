<template>
<div>
  <div class="order-cart" v-thematic>
    <CardHeader :number="number" cartMode v-if="!isInside" />
    <div v-if="isInside">
      <InsideCardHeader :isHotel="isHotel" />
      <InsideStatusBar :index="number - 1" />
    </div>
    <CardList
      :list="cart"
      @changeQuantity="onChangeQuantity"
      :freeze="freeze"
      editable
    />

    <!-- <div class="total-block">
      <h4 class="total">{{ $t('list.total')}}</h4>
      <span class="amount">{{total}}{{currency.symbol}}</span>
    </div> -->

    <div class="total-block">
      <h4 class="total">{{ $t("list.total") }}</h4>
      <span class="amount">{{ total }}{{ currency.symbol }}</span>
    </div>

    <!-- <div class="promocode-container" v-if="isInside">
      
    </div> -->

    <!-- <LongButton
      class="m-auto"
      @click="emitSend"
      :disabled="disabled"
      v-if="total >= min_check && !hideSendButton"
      >{{ sendButtonText }}</LongButton
    > -->

    <div
      v-thematic
      class="cart-min-check"
      v-if="min_check && total < min_check"
    >
      <div class="cart-min-check-text">
        <img src="/icons/desktop/varn-icon.svg" alt="" />{{
          $t("cart.min_check")
        }}
      </div>

      <br />
      <span class="min-check-value">{{ min_check }}{{ currency.symbol }}</span>
    </div>
    <!-- <div class="cashback-box" v-if="cashback && total >= min_check">
      {{ $t('cart.cashback', {cashback})}}₴
    </div>-->

    <AlcoholicModal
      @confirm="emitSend"
      @cancel="alco_modal = false"
      v-if="alco_modal"
    />
  </div>
  <!-- <LongButton
      class="m-auto"
      @click="emitSend"
      :disabled="disabled"
      v-if="total >= min_check && hideSendButton"
    >{{ sendButtonText }}</LongButton> -->
</div>
</template>

<style scoped>
.order-cart {
  width: 90%;
  background: #ededed;
  margin: 0 auto 2.5vh;
  display: block;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.6vh 2.6vh 2.3vh 2.3vh;
}

/* .order-cart.dark {
  background: transparent;
} */

.total-block {
  padding: 2.5vh 5.4vw;
  margin-top: -1px;
}

.comment {
  background: #ededed;
  border-radius: 0 0 1.8vh 1.8vh;
  padding: 0 5.4vw 2.5vh;
}

.no-border {
  border-radius: 0;
}

.comment textarea {
  width: 100%;
  -webkit-appearance: none;
  margin-top: 1.9vh;
  border: 1px solid #a4a3a1;
  border-radius: 10px;
  transition: 0.3s;
  background: #ededed;
  display: block;
  padding: 0.5vh 2vw 0;
}

.comment textarea:hover,
.comment textarea:active {
  outline: none;
  color: #1d1d1b;
  border: 1px solid grey;
}

.comment h6 {
  font-weight: 500;
  font-size: 2.5vh;
  padding: 0;
  margin-bottom: 0;
}

.total {
  display: inline-block;
  margin: 0 !important;
  font-size: 3.2vh;
  text-align: initial;
  padding-top: 0.9vh;
  line-height: 0.8;
}

span.amount {
  float: right;
  margin-top: 0vh;
  line-height: 0.8;
  font-size: 4.6vh;
  font-weight: 500;
}

.cart-min-check {
  text-align: center;
  width: 100%;
  padding-bottom: 1.8vh;
  padding-left: 2.5vh;
  padding-right: 2.5vh;
}

.cart-min-check.dark {
  margin-top: -1vh;
  background: #ededed;
  border-radius: 0 0 2.3vh 2.3vh;
}

.promocode-container {
  color: #1d1d1b !important;
  padding: 0 5.4vw 2.5vh;
}

.min-check-value {
  font-size: 9.6vw;
  font-weight: 500;
}

.cashback-box {
  text-align: center;
  width: 100%;
  margin-top: 1vh;
  font-size: 2vh;
  padding: 0 5.4vw;
}
@media screen and (min-width: 1024px) {
  .order-cart {
    margin-top: 2.2222vh;
    width: 100%;
    border-radius: 1.6vh;
  }
  .total-block {
    padding: 2.2222vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .total {
    font-size: 2.6666666666666665vh;
    line-height: 3.111111111111111vh;
    padding-top: 0;
  }
  span.amount {
    font-size: 2.7777777777777777vh;
    line-height: 3.2222222222222223vh;
  }
  .cart-min-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-min-check-text {
    display: flex;
    align-items: center;
    width: 75%;
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
  }
  .cart-min-check-text img {
    width: 2.4444444444444446vh;
    margin-right: 1.11111vh;
  }
  .min-check-value {
    font-size: 2.7777777777777777vh;
    line-height: 3.2222222222222223vh;
  }
}
</style>

<script>
import CardHeader from "@/components/card/CardHeader.vue";
import CardList from "@/components/card/CardList.vue";

import AlcoholicModal from "@/components/modals/AlcoholicModal.vue";

import CartService from "@/services/cart";

import OrderHelpers from "@/services/order/helpers.js";

import InsideCardHeader from "@/components/card/InsideCardHeader.vue";
import InsideStatusBar from "@/components/card/InsideStatusBar.vue";

import PromocodeInput from "@/components/order-details/PromocodeInput.vue"
import CommentField from "@/components/order-details/CommentField.vue"


export default {
  data() {
    return {
      comment: null,
      promocode: null,

      alco_modal: false,
    };
  },
  watch: {
    comment() {
      this.$emit("setComment", this.comment);
    },
    promocode() {
      this.$emit('setPromocode', this.promocode);
    }
  },
  methods: {
    emitSend() {
      if (this.$store.getters["cart/hasExciseItem"] && !this.alco_modal) {
        this.alco_modal = true;
        return;
      }

      this.$emit("send", this.comment);
      this.alco_modal = false;
    },

    onChangeQuantity(index, delta) {
      this.$store.dispatch("cart/changeQuantity", {
        index,
        delta,
      });

      this.$store.dispatch("promotions/checkCart");
      this.$emit("updated");
    },
  },
  mounted() {
    this.$store.dispatch("promotions/checkCart");
  },
  computed: {
    total() {
      return OrderHelpers.computeTotal(this.cart);
    },
    sendButtonText() {
      if (this.disabled) {
        return this.$t("cart.wait");
      }

      if (this.target.preorder) {
        return this.$t("cart.next");
      }

      return this.$t("cart.send");
    },
    currency() {
      return this.$store.state.currency.current;
    },
    isInside() {
      return this.$route.name == "new_order_code";
    },
  },
  components: {
    CardHeader,
    CardList,
    AlcoholicModal,
    InsideCardHeader,
    InsideStatusBar,
    PromocodeInput,
    CommentField
  },
  props: {
    target: Object,
    cart: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
    number: Number,
    min_check: {
      type: Number,
      default: 0,
    },
    cashback: {
      type: Number,
      default: null,
    },
    hideSendButton: {
      type: Boolean,
      default: false,
    },
    freeze: {
      type: [Boolean, Number],
      default: false,
    },
  },
};
</script>
