var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.is_exclusive ? 'img-block' : 'img-regular'},[(_vm.product.image)?_c('img',{class:_vm.is_exclusive ? 'img-exclusive' : 'img-description',attrs:{"src":_vm.productImage,"alt":"product image"}}):_vm._e(),(!_vm.product.image)?_c('img',{class:_vm.is_exclusive ? 'img-exclusive' : 'img-description',attrs:{"src":_vm.is_exclusive ? '/icons/no-dish-exclusive.jpg' : '/icons/no-dish.jpg',"alt":"product image"}}):_vm._e(),_c('ARButton',{staticClass:"ar-icon",attrs:{"product":_vm.product}})],1),_c('div',{class:[!_vm.hideContent ? 'info' : 'info no-space']},[_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],class:[
        _vm.is_exclusive ? 'product-details lux-details' : 'product-details' ]},[_c('h4',{directives:[{name:"thematic",rawName:"v-thematic"}],class:[_vm.is_exclusive ? 'm-0 color-black' : 'm-0 color-black']},[_vm._v("\n        "+_vm._s(_vm.productDisplayName)+"\n      ")]),(_vm.uiTotalPrice)?_c('span',{directives:[{name:"thematic",rawName:"v-thematic"}],class:{
          price: true,
          'price-discount': !!_vm.promotionDiscount,
        }},[_vm._v(_vm._s(_vm.uiTotalPrice)+_vm._s(_vm.currency.symbol))]):_vm._e(),_c('p',{directives:[{name:"thematic",rawName:"v-thematic"},{name:"show",rawName:"v-show",value:(!_vm.hideContent && _vm.product.description),expression:"!hideContent && product.description"}],ref:"descriptionElement",staticClass:"ingridients сolor-grey",domProps:{"innerHTML":_vm._s(_vm.productDisplayDescription)}}),(_vm.can_expand_description && !_vm.hideContent && !_vm.expanded_description)?_c('span',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"ingridients show-more",on:{"click":function($event){_vm.expanded_description = true;
          _vm.can_expand_description = false;}}},[_vm._v("\n        "+_vm._s(_vm.$t("menu.show_more"))+"\n      ")]):_vm._e(),(_vm.totalWeight || _vm.product.waiting_time)?_c('div',{staticClass:"misc-params"},[(_vm.totalWeight)?_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/mass.svg")}}):_vm._e(),(_vm.totalWeight)?_c('p',{class:_vm.isDarkMode ? 'weight color-white mb-0' : 'weight color-black mb-0'},[_vm._v("\n          "+_vm._s(_vm.totalWeightDisplayText)+"\n        ")]):_vm._e(),(_vm.product.waiting_time)?_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/timer-dark.svg")}}):_vm._e(),(_vm.product.waiting_time)?_c('p',{staticClass:"waiting-time color-grey"},[_vm._v("\n          "+_vm._s(_vm.product.waiting_time)+" "+_vm._s(_vm.$t("units.minutes"))+"\n        ")]):_vm._e(),(_vm.pricePerUnit)?_c('p',{staticClass:"waiting-time mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t("menu.price_per_unit", {
              unit: _vm.pricePerUnit.unit,
            }))+"\n          "+_vm._s(_vm.pricePerUnit.price)+" "+_vm._s(_vm.currency.symbol)+"\n        ")]):_vm._e()]):_vm._e(),(!_vm.hideContent && !_vm.isOnlyOnePrice)?_c('div',{staticClass:"price-groups"},_vm._l((_vm.product.price_groups || []),function(price_group,index){return _c('div',{key:index,staticClass:"price-group"},[(price_group.single_select)?_c('div',[(_vm.canShowPriceGroup(price_group))?_c('VariantsGroup',{attrs:{"group":price_group,"dish":_vm.product,"selected":_vm.cartItem.variants,"selectedModificators":_vm.cartItem.modificators,"show_prices":_vm.isMultipleVariants,"forceNoCheck":_vm.target.cafe_id == 318},on:{"add":function (v) { return _vm.cartItem.variants.push(v); },"remove":function (i) { return _vm.cartItem.variants.splice(i, 1); },"removeModificator":function (i) { return _vm.cartItem.modificators.splice(i, 1); }}}):_vm._e()],1):_c('div',[(_vm.canShowPriceGroup(price_group))?_c('ModificatorsGroup',{attrs:{"group":price_group,"selected":_vm.cartItem.modificators},on:{"toggle":_vm.onModificatorToggle}}):_vm._e()],1)])}),0):_vm._e(),(_vm.canAddToCart)?_c('div',{staticClass:"quantity"},[(_vm.isDarkMode)?_c('img',{style:({
            visibility: _vm.cartItem.quantity > 1 ? 'visible' : 'hidden',
          }),attrs:{"src":"/icons/btn-minus.svg","alt":""},on:{"click":function($event){return _vm.changeQuantity(-1)}}}):_c('img',{style:({
            visibility: _vm.cartItem.quantity > 1 ? 'visible' : 'hidden',
          }),attrs:{"src":"/icons/btn-white-minus.svg","alt":""},on:{"click":function($event){return _vm.changeQuantity(-1)}}}),_c('span',{directives:[{name:"thematic",rawName:"v-thematic"}]},[_vm._v(_vm._s(_vm.quantityDisplayText))]),(_vm.isDarkMode)?_c('img',{style:({
            visibility: _vm.hasEnoughProductInStock ? 'visible' : 'hidden',
          }),attrs:{"src":"/icons/btn-plus.svg","alt":""},on:{"click":function($event){return _vm.changeQuantity(1)}}}):_c('img',{style:({
            visibility: _vm.hasEnoughProductInStock ? 'visible' : 'hidden',
          }),attrs:{"src":"/icons/btn-white-plus.svg","alt":""},on:{"click":function($event){return _vm.changeQuantity(1)}}})]):_vm._e(),(_vm.canAddToCart)?_c('button',{class:_vm.is_exclusive ? 'add-to-cart lux-add' : 'add-to-cart color-white',on:{"click":_vm.addToCart}},[_vm._v("\n        "+_vm._s(_vm.$t("menu.add_to_cart"))+"\n        "),(_vm.uiTotalPrice)?_c('span',{staticClass:"color-white"},[_vm._v(_vm._s(_vm.uiTotalPrice)+_vm._s(_vm.currency.symbol))]):_vm._e()]):_vm._e(),(_vm.hideContent)?_c('p',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"added2"},[_c('img',{attrs:{"src":"/icons/ok.png"}}),_vm._v("\n        "+_vm._s(_vm.$t("menu.added_to_cart"))+"\n      ")]):_vm._e(),(_vm.menuClosedWay > 0)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"closed-bar"},[_c('img',{attrs:{"src":"/icons/warning.svg"}}),_c('p',[_vm._v(_vm._s(_vm.$t("menu.menu_closed_before"))+" "+_vm._s(_vm.menuClosedTime))])]):_vm._e(),(_vm.menuClosedWay < 0)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"closed-bar"},[_c('img',{attrs:{"src":"/icons/warning.svg"}}),_c('p',[_vm._v(_vm._s(_vm.$t("menu.menu_closed_after"))+" "+_vm._s(_vm.menuClosedTime))])]):_vm._e(),(!_vm.resultProductStock)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"closed-bar"},[_c('img',{attrs:{"src":"/icons/warning.svg"}}),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t("menu.out_of_stock"))+"\n        ")])]):_vm._e()])]),(!_vm.target.lite && _vm.recommendations.length)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"reccomendations"},[_c('ProductList',{attrs:{"products":_vm.recommendations,"category":_vm.$t('menu.recommending')},on:{"select":_vm.emitRecommendationSelect}}),_c('SupportIcon')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }