import BaseAPI from '../base'

function fetch(menu_ids, product_id) {
    return BaseAPI.sendGet(`/recommendations/fetch`, {
        menu_id: menu_ids.join(','),
        product_id
    });
}

function commit(data) {
    return BaseAPI.sendPost(`/recommendations/commit`, data);
}

export default {
    fetch,
    commit
}