<template>
  <img src="/icons/ar.svg" @click="openAr" v-if="canShowIcon">
</template>

<script>
export default {
    props: {
        product: Object
    },
    methods: {
        checkAndroidSupport() {
            return true;
        },
        checkIosSupport() {
            const a = document.createElement("a");
            const support = a.relList.supports("ar");

            a.remove();

            return support;
        },
        openIos() {
            const anchor = document.createElement('a');
            anchor.setAttribute('rel', 'ar');
            anchor.appendChild(document.createElement('img'));
            anchor.setAttribute('href', this.modelUrl);
            anchor.click();
        },
        openAndroid() {
            const a = document.createElement('a');

            a.href = `intent://arvr.google.com/scene-viewer/1.0?mode=ar_preferred&disable_occlusion=true&file=${encodeURIComponent(this.modelUrl)}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(window.location.href)};end;`;
            
            // alert(a.href);
            a.click();
        },
        openAr() {
            if (this.isAndroid) {
                this.openAndroid();
            } else {
                this.openIos();
            }
        }
    },
    computed: {
        isAndroid() {
            const ua = window.navigator.userAgent;

            return /Android/gi.test(ua);
        },
        modelUrl() {
            if (this.isAndroid) {
                return this.product.ar_android_url;
            } else {
                return this.product.ar_ios_url;
            }

            // return this.isAndroid ? 'https://order.johnnysandbox.site/ar/sushi.gltf' : 'https://aestar.com.ua/wp-content/uploads/2021/02/sushi.usdz'
        },
        canShowIcon() {
            if (!this.modelUrl) return false;

            if (this.isAndroid) {
                return this.checkAndroidSupport();
            } else {
                return this.checkIosSupport();
            }
        }
    }
}
</script>

<style>

</style>