<template>
  <div @click="$emit('click')" class="special-product-card-desktop">
    <img :src="productImage" class="bg-img" alt="" />
    <div class="product-info-block">
      <div class="row-space-between">
        <div class="product-title">{{ displayName }}</div>
        <div class="product-price">{{ firstPrice }}{{ currency.symbol }}</div>
      </div>
      <!-- <div class="product-description">{{ displayDescription }}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: Object,
  },
  computed: {
    firstPrice() {
      const base_price = this.product.base_price || 0;

      const variants_groups = this.product.price_groups.filter(
        (g) => g.single_select
      );

      let variants_add = 0;

      for (let i = 0; i < variants_groups.length; i++) {
        const items = variants_groups[i].items;

        variants_add += items[0].price;
      }

      // if (variants_group && variants_group.items[0] && variants_group.items[0].price) {
      //   return variants_group.items[0].price + base_price;
      // }

      return base_price + variants_add;
    },
    currency() {
      return this.$store.state.currency.current;
    },
    displayName() {
      return this.$store.getters["menu/localize"](
        "products",
        this.product.name
      );
    },

    displayDescription() {
      return this.$store.getters["menu/localize"](
        "descriptions",
        this.product.description
      );
    },

    productImage() {
      if (!this.product.image) {
        return "/icons/no-dish-exclusive.jpg";
      }

      // if (this.product.image.includes('joinposter.com')) {
      //   return this.product.image.replace('_original', '');
      // }

      return this.product.image;
    },
  },
};
</script>
<style scoped>
.special-product-card-desktop {
  width: 60vh;
  height: 30vh;
  border-radius: 1.3333333333333333vh;
  position: relative;
  margin-right: 1.6666666666666667vh;
  cursor: pointer;
  overflow: hidden;
}
.special-product-card-desktop:last-child {
  margin-right: 0;
}
.bg-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.3333333333333333vh;
  z-index: 1;
}
.product-info-block {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 0 1.3333333333333333vh 1.3333333333333333vh;
  background: rgba(29, 29, 27, 0.7);
  backdrop-filter: blur(20px);
  padding: 1.1111111111111112vh 1.5555555555555556vh;
  width: 100%;
  height: auto;
  z-index: 2;
}
.row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8888888888888888vh;
}
.product-title {
  font-weight: bold;
  font-size: 2.6666666666666665vh;
  line-height: 3.111111111111111vh;
  color: #ededed;
  width: fit-content;
  padding-right: 2.2vh;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-price {
  font-weight: normal;
  font-size: 3.3333333333333335vh;
  line-height: 3.888888888888889vh;
  color: #ededed;
}
.product-description {
  font-weight: normal;
  font-size: 1.7777777777777777vh;
  line-height: 2vh;
  color: #ededed;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
